import { createContext } from "react";

//Types
import { BarControlContextType } from "@/@types/components/Map/Controls/OpenLayersExt/Bar";

const BarControlContext = createContext<BarControlContextType | null>(null);

BarControlContext.displayName = "BarControlContext";

export default BarControlContext;
