import moment from "moment";

export {
  sortDateTime
, sortBasic
, sortElementProperty
, sortFavorite
, sortLink
, sortMulti
}

import { Row } from 'react-table';

import {
  SortResult,
  SignFn,
  SortFn,
  WrappedSortFn,
  SortWrapperFn,
} from "@/@types/ui/Table";

const sortDateTime: SortFn = (rowA, rowB, columnId, desc) => 
  sortWrapper(wrappedSortDateTime, rowA, rowB, columnId, desc);
const sortBasic: SortFn = (rowA, rowB, columnId, desc) => 
  sortWrapper(wrappedSortBasic, rowA, rowB, columnId, desc);
const sortElementProperty: SortFn = (rowA, rowB, columnId, desc) => 
  sortWrapper(wrappedSortElementProperty, rowA, rowB, columnId, desc);
const sortFavorite: SortFn = (rowA, rowB, columnId, desc) => 
  sortWrapper(wrappedSortFavorite, rowA, rowB, columnId, desc);
const sortLink: SortFn = (rowA, rowB, columnId, desc) => 
  sortWrapper(wrappedSortLink, rowA, rowB, columnId, desc);
const sortMulti: SortFn = (rowA, rowB, columnId, desc) => 
  sortWrapper(wrappedSortMulti, rowA, rowB, columnId, desc);

const signFn: SignFn = (n: number) => (n === 0 ? 0 : n > 0 ? 1 : -1);

const sortWrapper: SortWrapperFn = (fn, rowA, rowB, columnId, desc) => {
  const res = fn(rowA, rowB, columnId);
  return signFn(desc ? (-1 * res) : res);
}

const wrappedSortDateTime: WrappedSortFn = (rowA, rowB, columnId) => {
  const momA = moment.isMoment(rowA.values[columnId]) ? rowA.values[columnId] : moment(rowA.values[columnId])
  const momB = moment.isMoment(rowB.values[columnId]) ? rowB.values[columnId] : moment(rowB.values[columnId])
  if (moment.isMoment(momA) && moment.isMoment(momB)) {
    if (momA.isAfter(momB)) {
      return 1;
    } else {
      return -1;
    }
  } else if (momA !== null && momB === null) {
    return -1;
  } else if (momA === null && momB !== null) {
    return 1;
  } else {
    return 1;
  }
}

const wrappedSortBasic: WrappedSortFn = (rowA, rowB, columnId) => {
  const valA = rowA.values[columnId] ? rowA.values[columnId] : null;
  const valB = rowB.values[columnId] ? rowB.values[columnId] : null;

  // There two ifs are here to ensure that falsey values always end up at the end of the table
  // Curious reader will ask why not just check if(valA) well because 0 is falsey value so it will end up somewhere between the empty values
  //    which is not what we want since 0 is indeed a falsey value but it is nonetheless a VALUE
  if (valA === null || valA === undefined || valA === "") {
    return 1;
  }
  if (valB === null || valB === undefined || valB === "") {
    return -1;
  }

  if (typeof valA === "number" && typeof valB === "number") {
    return valA - valB;
  } else if (typeof valA === "string" && typeof valB === "string") {
    return valA.toUpperCase() > valB.toUpperCase() ? 1 : -1;
  } else if (columnId === "active") {
    return valA() > valB() ? 1 : -1;
  } else {
    // Not sure what to do with arrays and objects so lets just do this and see what happens
    return valA > valB ? 1 : -1;
  }
}

const wrappedSortElementProperty: WrappedSortFn = (rowA, rowB, columnId) => {
  const valA = rowA.values[columnId] ? rowA.values[columnId].props.property : null;
  const valB = rowB.values[columnId] ? rowB.values[columnId].props.property : null;

  return (Number(valB) - Number(valA));
}

const wrappedSortFavorite: WrappedSortFn = (rowA, rowB, columnId) => {
  // By design they both must have props.value otherwise this will crash
  const valA = rowA.values[columnId] ? rowA.values[columnId].props.value : null;
  const valB = rowB.values[columnId] ? rowB.values[columnId].props.value : null;

  // Number(false) = 0, Number(true) = 1
  // a - b (difference) -> sorts elements in ascending order, since we want descending (true before false or 1 before 0) we do it the other way around
  return (Number(valB) - Number(valA));
}

const wrappedSortLink: WrappedSortFn = (rowA, rowB, columnId) => {
  // By design they both must have props.value otherwise this will crash
  const valA = rowA.values[columnId] ? rowA.values[columnId].props.value : null;
  const valB = rowB.values[columnId] ? rowB.values[columnId].props.value : null;

  // Number(false) = 0, Number(true) = 1
  // a - b (difference) -> sorts elements in ascending order, since we want descending (true before false or 1 before 0) we do it the other way around
  return valA.toUpperCase() > valB.toUpperCase() ? 1 : -1;

}

const wrappedSortMulti: WrappedSortFn = (rowA, rowB, columnId) => {
  const valA = rowA.values[columnId] ? rowA.values[columnId].props.property.length : null;
  const valB = rowB.values[columnId] ? rowB.values[columnId].props.property.length : null;

  return (Number(valB) - Number(valA));
}
