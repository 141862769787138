import * as React from "react";
import { Fragment, useState, FunctionComponent, ReactElement, ReactNode, SyntheticEvent } from "react";

//MUI
import { useTheme } from "@mui/material";
import Menu from "@mui/material/Menu";

//MUI Icons
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Custom Components
import HeaderButton from "@/ui/Header/HeaderButton";
import HeaderMenuItem from "@/ui/Header/HeaderMenuItem";

//Types
import { IHeaderMenuButton } from "@/@types/ui/Header";
import { PopoverProps } from "@mui/material/Popover";

const HeaderMenuButton: FunctionComponent<IHeaderMenuButton> = (props) => {
  const [anchorEl, setAnchorEl] = useState<PopoverProps["anchorEl"]>(null);

  const theme = useTheme();
  const { icon, text, toggleSidebar, startIcon, endIcon, showExpandIcon, desc, ...rest } = props;

  const handleClick = (evt: SyntheticEvent): void => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = (evt: SyntheticEvent, reason: string): void => {
    setAnchorEl(null);
  };

  const hookAction = (action: () => void): void => {
    setAnchorEl(null);
    action();
  };

  const buildMenuItem = (node: ReactElement, index: number): ReactNode => {
    const { onClick, label, icon } = node.props;
    return <HeaderMenuItem onClick={() => hookAction(onClick)} label={label} icon={icon} key={index} />;
  };

  let menuItems = [];
  if (Array.isArray(props.children)) {
    props.children.forEach((child, i) => {
      if (child !== null && child !== undefined) {
        if ((child as ReactElement).type === HeaderMenuItem) {
          menuItems.push(buildMenuItem(child as ReactElement, i));
        } else {
          menuItems.push(child);
        }
      }
    });
  } else {
    if (props.children !== null && props.children !== undefined) {
      menuItems.push(buildMenuItem(props.children, 1));
    }
  }

  return (
    <Fragment>
      <HeaderButton
        // variant="outlined"
        onClick={toggleSidebar ? toggleSidebar : handleClick}
        startIcon={startIcon}
        endIcon={
          showExpandIcon ? (
            <ExpandMoreIcon
              sx={{
                transform: anchorEl !== null ? "rotate(180deg)" : "rotate(0deg)",
                marginLeft: "auto",
                transition: theme.transitions.create("transform", {
                  duration: theme.transitions.duration.shortest
                })
              }}
            />
          ) : null
        }
        desc={desc}
        {...rest}
      >
        {text ? text : null}
        {icon ? icon : null}
        {icon === undefined && text === undefined ? <MenuIcon /> : null}
      </HeaderButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          ".MuiMenu-paper": {
            backgroundColor: "primary.main",
            color: "common.white"
          },
          ".MuiMenu-list": {
            color: "common.white",
            padding: "1px 0"
          }
        }}
      >
        {menuItems}
      </Menu>
    </Fragment>
  );
};

export default HeaderMenuButton;
