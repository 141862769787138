import React, { useState } from "react"
import { useTranslation } from "react-i18next";

import { ResetPassword } from "@/@types/views/Auth/passwordreset";
import { IDataController } from "@/@types/lib/dataController"

//Material-UI Core Components
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

//Custom Components
import PublicPage from "@/ui/PublicPage/PublicPage";
import GridContainer from "@/ui/Grid/GridContainer";
import GridItem from "@/ui/Grid/GridItem";
import PasswordResetRequestForm from "@/views/Auth/PasswordResetRequestForm";

import model from "@/models/user";
import dataController from "@/lib/dataController";

function PasswordResetRequestPage() {

  const [status, setStatus] = useState<ResetPassword>({completed: false, success: false});
  const { t } = useTranslation();

  const dc: IDataController = new dataController(model);

  const handleCompleted = (result: { success: boolean}) => {
    if (result.success) {
      setStatus({
        completed: true,
        success: true
      })
    } else {
      setStatus({
        completed: true,
        success: false
      })
    }
  }

  return (
    <PublicPage>
      <Box m={2}>
        <Container maxWidth={"sm"}>
          { !status.completed ?
            <PasswordResetRequestForm dc={dc} form="password_reset_request" mode="update" onCompleted={handleCompleted} onClose={() => {}}/>
            : <Card>
              <CardContent>
              <GridContainer>
                  <GridItem xs={12}>
                    <Typography variant="h5" component="h3">
                      { status.success ? t("titles.password_reset_request_success") : t("titles.password_reset_request_fail")}
                    </Typography>
                  </GridItem>
                  <GridItem xs={12}>
                    <Typography variant="body1" component="span">
                      { status.success ? t("messages.password_reset_request_success") : t("messages.password_reset_request_fail")}
                    </Typography>
                  </GridItem>
                </GridContainer>

              </CardContent>
            </Card>
          }
        </Container>
      </Box>
    </PublicPage>
  )

}

export default PasswordResetRequestPage;
