import { FunctionComponent, useContext, useEffect } from "react";

//OpenLayers
import { DragZoom } from "ol/interaction";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlInteraction } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IDragZoomInteraction } from "@/@types/components/Map/Interactions/OpenLayers";

const DragZoomInteraction: FunctionComponent<IDragZoomInteraction> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    className: undefined,
    condition: undefined,
    duration: undefined,
    out: undefined,
    minArea: undefined
  };

  const events = {
    boxdrag: undefined,
    boxend: undefined,
    boxstart: undefined,
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, DragZoom, props, options, events),
    []
  );

  return null;
};

export default DragZoomInteraction;

