import * as React from "react";
import { FunctionComponent, useContext, Fragment } from "react";

//MUI
import Button from "@mui/material/Button";

//Custom Components
import UserContext from "@/components/UserContext/UserContext";

//Types
import { IFormButton } from "@/@types/ui/Form";
import { UserContextType } from "@/@types/context/UserContext";

const FormButton: FunctionComponent<IFormButton> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;
  const { needPermission, ...rest } = props;

  return (
    <Fragment>
      {needPermission && !userContext.hasAnyPermission(needPermission) ? null : (
        <Button
          color="primary"
          size="medium"
          sx={{
            mx: 1
          }}
          {...rest}
        >
          {props.children}
        </Button>
      )}
    </Fragment>
  );
};

export default FormButton;
