import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";

//MUI Icons
import CloudDownloadSharpIcon from "@mui/icons-material/CloudDownloadSharp";

//Custom Components
import TableHeaderButton from "./TableHeaderButton";
import { exportTableToExcel } from "@/lib/exportExcel";
import { ITableHeaderButtonExportProps } from "@/@types/ui/Table";

function TableHeaderButtonExport(props: ITableHeaderButtonExportProps) {
  const { t } = useTranslation();
  const { dc, rows, records, tableName, ...rest } = props;

  const handleExport = function (evt: MouseEvent<HTMLButtonElement>) {
    let filteredRecords = new Array();
    for (let i = 0; i < rows.length; i++) {
      filteredRecords.push(records[parseInt(rows[i].id)]);
    }

    const fields = dc.getViewFields("exportExcel");
    const obj = {
      records: filteredRecords,
      fields: fields,
      filename: tableName ? tableName : dc.filename ? dc.filename : "",
      t: t
    };
    exportTableToExcel(obj);
  };

  return (
    <TableHeaderButton
      startIcon={<CloudDownloadSharpIcon />}
      variant="outlined"
      {...rest}
      onClick={handleExport}
      sx={{ display: { xs: "none", sm: "flex" } }}
    >
      {t("buttons.export")}
    </TableHeaderButton>
  );
}

export default TableHeaderButtonExport;
