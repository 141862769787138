type Point3D = [number, number, number];

function roundToPrecision(value: number, precision: number = 6): number {
    return parseFloat(value.toFixed(precision));
}

/**
 * Generates a DXF (Drawing Exchange Format) string representing the provided 3D points.
 * The function accepts an array of 3D points consisting of latitude (X), longitude (Y), 
 * and altitude (Z) values. These 3D points are then represented as consecutive line segments 
 * in the DXF format suitable for applications like AutoCAD.
 *
 * @param pointsXYZ - An array of 3D points where each point contains latitude (X), longitude (Y),
 *                    and altitude (Z) values.
 *
 * @returns A string in DXF format representing the 3D lines derived from the provided 3D points.
 */
function generateDXF(pointsXYZ: Point3D[]) {
    const header = "  0\nSECTION\n  2\nHEADER\n  0\nENDSEC\n";
    const entitiesStart = "  0\nSECTION\n  2\nENTITIES\n";

    // Generate line entities for each consecutive pair of points
    const lineEntities: string[] = [];
    for (let i = 0; i < pointsXYZ.length - 1; i++) {
        const startPoint = pointsXYZ[i].map(val => roundToPrecision(val));
        const endPoint = pointsXYZ[i + 1].map(val => roundToPrecision(val));
        const lineEntity = `  0\nLINE\n  8\n  0\n` +
                        ` 10\n${startPoint[0]}\n 20\n${startPoint[1]}\n 30\n${startPoint[2]}\n` +
                        ` 11\n${endPoint[0]}\n 21\n${endPoint[1]}\n 31\n${endPoint[2]}\n`;
        lineEntities.push(lineEntity);
    }

    const entitiesEnd = "0\nENDSEC\n";
    const footer = "0\nEOF\n";

    // Construct the full DXF content
    const dxfContent = header + entitiesStart + lineEntities.join("") + entitiesEnd + footer;

    return dxfContent;

}

export default generateDXF