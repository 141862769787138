import * as React from "react";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Divider from "@mui/material/Divider";

//MUI Icons
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";

//Custom Components
import HeaderMenuIconButton from "@/ui/Header/HeaderMenuIconButton";
import HeaderMenuItem from "@/ui/Header/HeaderMenuItem";

const AccessibilityMenu = () => {
  const [greyscale, setGreyscale] = useState(false);
  const [contrast, setContrast] = useState(false);
  const { t } = useTranslation();

  const html = document.getElementsByTagName("html")[0];
  const body = document.getElementsByTagName("body")[0];

  const fontSizePercentage = (value: string | null | ""): number => {
    if (value && value === "") {
      return 100;
    } else if (value && value.indexOf("%") > 0) {
      const ind = value.indexOf("%");
      const perc = parseInt(value.substr(0, ind), 10);
      return perc;
    } else {
      return 100;
    }
  };

  const handleFontLarger = (): void => {
    const currentPercSize = fontSizePercentage(html.style.fontSize);
    if (currentPercSize < 200) {
      html.style.fontSize = (currentPercSize + 20).toString() + "%";
    }
  };

  const handleFontSmaller = (): void => {
    const currentPercSize = fontSizePercentage(html.style.fontSize);
    if (currentPercSize > 40) {
      html.style.fontSize = (currentPercSize - 20).toString() + "%";
    }
  };

  const handleGreyscale = (): void => {
    body.style.filter = greyscale ? "" : "grayscale(100%)";
    setGreyscale((val) => !val);
    setContrast(false);
  };

  const handleContrast = (): void => {
    body.style.filter = contrast ? "" : "contrast(150%)";
    setContrast((val) => !val);
    setGreyscale(false);
  };

  const handleReset = (): void => {
    html.className = "";
    html.style.fontSize = "";
    body.style.filter = "";
  };

  return (
    <HeaderMenuIconButton icon={<AccessibilityNewIcon />} desc="a11y" keepOpen={true}>
      <HeaderMenuItem label={t("menu.a11y.povecaj_tekst")} onClick={handleFontLarger} />
      <HeaderMenuItem label={t("menu.a11y.smanji_tekst")} onClick={handleFontSmaller} />
      <Divider />
      <HeaderMenuItem label={t("menu.a11y.greyscale")} onClick={handleGreyscale} />
      <HeaderMenuItem label={t("menu.a11y.kontrast")} onClick={handleContrast} />
      <Divider />
      <HeaderMenuItem label={t("menu.a11y.reset")} onClick={handleReset} />
    </HeaderMenuIconButton>
  );
};

export default AccessibilityMenu;
