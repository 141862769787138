import * as React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Icons
import LockIcon from "@mui/icons-material/Lock";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

//Custom Controls
import BaseInputControl from "./BaseInputControl";

//Types
import { ILinkControlProps } from "@/@types/controls/controls";

function LinkControl(props: ILinkControlProps) {
  // const dialogContext = useContext(DialogContext);

  const { t } = useTranslation();

  const { field, value, onChange, controlMode, linkType, ...otherProps } = props;

  const isReadOnly = controlMode === "view" || (field && field.readonly);
  const disabled = value && value.length > 0 ? false : true;

  const endAdornmentIcons = [];

  const handleChange = (value: string | number | null, source: string) => {
    onChange(value as string, source);
  }

  switch (linkType) {
    case "mail":
      const mailHrefUrl = disabled ? undefined : "mailto:" + value;
      endAdornmentIcons.push(
        <a key="mailKey" tabIndex={-1} href={mailHrefUrl} target={"_blank"} style={{ color: !disabled ? "initial" : "darkgray" }}>
          <MailOutlineIcon color="primary" />
        </a>
      );
      break;
    case "link":
      const linkHrefUrl = disabled ? undefined : (value !== null ? value : undefined);
      endAdornmentIcons.push(
        <a key="linkKey" tabIndex={-1} href={linkHrefUrl} target={"_blank"} style={{ color: !disabled ? "initial" : "darkgray" }}>
          <MailOutlineIcon color="primary" />
        </a>
      );
      break;
  }

  //TODO: Key error here with IconButton

  if (isReadOnly) {
    endAdornmentIcons.push(<LockIcon style={{ fontSize: 20 }} color="disabled"/>);
  }

  const InputProps = {
    readOnly: isReadOnly,
    endAdornment: endAdornmentIcons
  };

  return (
    <BaseInputControl
      field = {field}
      controlMode={controlMode}
      {...otherProps}
      onFocus={() => {}}
      value={value as string}
      onChange={handleChange}
      inputProps={InputProps}
    />
  );
}

export default LinkControl;
