import * as React from "react";
import { FunctionComponent, useRef } from "react";

//MUI
import { useTheme } from "@mui/material";

const SidebarContent: FunctionComponent<any> = (props) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  const theme = useTheme();

  const element = document.createElement("div");
  element.className = "sidebar-content";

  contentRef.current = element;

  return (
    <div
      className={"sidebar-content"}
      style={{
        backgroundColor: theme.palette.background.default
      }}
    >
      {props.children}
    </div>
  );
};

export default SidebarContent;
