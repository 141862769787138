import * as React from "react";
import { FunctionComponent } from "react";

//MUI
import Grid from "@mui/material/Grid";

//Types
import { GridItemProps } from "@/@types/ui/Grid";

const GridItem: FunctionComponent<GridItemProps> = (props) => {
  return (
    <Grid item {...props}>
      {props.children}
    </Grid>
  );
};

export default GridItem;
