import { IModel } from "@/@types/models/model";
import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";

const Parcelacija: IModel = {
  title: "Parcelacija",
  apiPath: "data/parcelacija",
  forms: {
    "default": {
      fields: ["id", "opis", "kc", "ko_mb", "ko_ime", "povrsina"]
    }
  },
  listViews: {
    "default": {
      fields: ["id"]
    }
  },
  fields: [
    coreFields.id,
    commonFields.opis,
    commonFields.kc,
    commonFields.ko_mb,
    commonFields.ko_ime,
    commonFields.povrsina
  ]
}

export default Parcelacija;