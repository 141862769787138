import { IModel } from "@/@types/models/model";
import { commonFields } from "./helpers/commonFields";
import { coreFields } from "./helpers/coreFields";

const Posjednik: IModel = {
  title: "Posjednici",
  apiPath: "data/dkp2/{dkp_id}/posjednici",
  forms: {
    "default": {
      fields: ["id"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "pl", "udio", "naziv", "adresa"]
    }
  },
  fields: [
    coreFields.id,
    { 
      title: "Posjedovni list ID",
      source: "pl",
      ttoken: "posjednici.posjedovni_list",
      type: "wholenum",
      readonly: true
    },
    { 
      title: "Udio",
      source: "udio",
      ttoken: "posjednici.udio",
      type: "text",
      readonly: true
    },
    { 
      title: "Naziv",
      source: "naziv",
      ttoken: "posjednici.naziv",
      type: "text",
      readonly: true
    },
    { 
      title: "Adresa",
      source: "adresa",
      ttoken: "posjednici.adresa",
      type: "text",
      readonly: true
    },
    { 
      title: "OIB",
      source: "oib",
      ttoken: "common.oib",
      type: "text",
      readonly: true
    },
    { 
      title: "Posjedovni list",
      source: "ko_pl",
      ttoken: "posjednici.posjedovni_list",
      type: "text",
      readonly: true
    },
    { 
      title: "Katastarska općina",
      source: "ko",
      ttoken: "posjednici.katastarska_opcina",
      type: "text",
      readonly: true
    }
  ]
}

export default Posjednik;
