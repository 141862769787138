import * as React from "react";
import { FunctionComponent } from "react";

//MUI
import Grid from "@mui/material/Grid";

//Types
import { GridContainerProps } from "@/@types/ui/Grid";

const GridContainer: FunctionComponent<GridContainerProps> = (props) => {
  return (
    <Grid container spacing={props.spacing ? props.spacing : 2} {...props}>
      {props.children}
    </Grid>
  );
};

export default GridContainer;
