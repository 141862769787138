import * as React from "react";
import { FunctionComponent, useState } from "react";

//Custom components
import MapConfigContext from "./MapConfigContext";

//Types
import { IMapConfigProvider } from "@/@types/context/MapConfigContext";

const MapConfigProvider: FunctionComponent<IMapConfigProvider> = (props) => {

  const [revision, setRevision] = useState<number>(0);
  const [userLayers, setUserLayers] = useState<any[]>([]);
  const [layer, setLayer] = useState<any | undefined>(undefined);
  const [filtered, setFiltered] = useState<boolean>(false);

  const addLayer = (layer: any): void => {
    setRevision((prevState) => prevState + 1);
    setUserLayers((prevState) => prevState.concat(layer))
  }

  const setGridLayer = (layer: any): void => {
    if (layer) {
      setLayer(layer)
    } else {
      setLayer(undefined)
    }
  }

  const providerValue = {
    revision,
    userLayers,
    layer,
    filtered,
    setFiltered,
    addLayer,
    setGridLayer
  }

  return (
    <MapConfigContext.Provider value={providerValue}>
      {props.children}
    </MapConfigContext.Provider>
  );
};

export default MapConfigProvider;

