import React, { Fragment } from "react";
import { GetHeaderFn } from "@/@types/ui/Table"

import Tooltip from '@mui/material/Tooltip';

export const getHeader: GetHeaderFn = (field, t) => {
	const headerString = field.ttoken ? t(field.ttoken) : field.title;
	const Component = field.type === "numeric" ? 
		<div style={{ textAlign: "end" }}>{headerString}</div>
		: <Fragment>{headerString}</Fragment>;

	if (field.tooltip) {
		return (
			<Tooltip placement="top-end" title={t(field.tooltip) as string}>
				{Component}
			</Tooltip>
		);
	} else {
		return Component;
	}
}