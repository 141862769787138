import React, { FunctionComponent, useContext } from "react";

//MUI
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

//Custom components
import SidebarContext from "./SidebarContext/SidebarContext";

const SidebarTabs: FunctionComponent<any> = (props) => {
  const sidebarContext = useContext(SidebarContext);
  const theme = useTheme();

  return (
    <Box m={0} p={0}
      className={"sidebar-tabs"}
      sx={{
        top: 0,
        bottom: 0,
        height: "100%",
        bgcolor: "primary.dark"
      }}
    >
      {props.children}
    </Box>
  );
};

export default SidebarTabs;
