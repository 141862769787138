import React, { useState, useEffect, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

//MUI
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteChangeReason } from "@mui/material/Autocomplete";

//MUI Icons
import LockIcon from "@mui/icons-material/Lock";

//Custom Components
import { fieldFormat } from "@/lib/fieldFormat";

//Types
import { IPickerControlProps, PickerItem } from "@/@types/controls/controls";
import { IFieldPickerModel, PickerItemValue } from "@/@types/models/model";

function PickerControl(props: IPickerControlProps) {
  const [inputValue, setInputValue] = useState("");
  const [items, setItems] = useState<Array<PickerItem>>([]);
  const [renderItems, setRenderItems] = useState<Array<PickerItem>>([]);

  const { t } = useTranslation();

  const { field, value, onChange, validation, formMode, controlMode, dc } = props;

  const offset = 100;

  useEffect(() => {
    refreshItems();
  }, []);

  useEffect(() => {
    refreshItems();
  }, [dc]);

  useEffect(() => {
    if (inputValue.length === 0 && renderItems.length === 0 && items.length > 0) {
      getFilteredItems();
    }
  }, [inputValue, items, renderItems]);

  const handleChange = (evt: SyntheticEvent, value: PickerItem | null, reason: AutocompleteChangeReason) => {
    switch (reason) {
      case "createOption":
        break;
      case "selectOption":
        applyChange(value);
        break;
      case "removeOption":
        break;
      case "clear":
        applyChange(null);
        break;
      case "blur":
        break;
    }
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  const applyChange = (option: PickerItem | null) => {
    if (onChange) {
      onChange(option, field.source);
    }
  };

  // const getItemLabel = (obj, format) => {
  //   let keys = Object.keys(obj);

  //   if (format) {
  //     let label = format;
  //     if (keys.length > 0) {
  //       keys.forEach((k) => {
  //         let re = new RegExp("{" + k + "}");
  //         label = label.replace(re, obj[k]);
  //       });
  //       return t(label);
  //     } else {
  //       return null;
  //     }
  //   } else {
  //     return keys.map((x) => t(obj[x])).join(", ");
  //   }
  // };

  const getItem = (value: PickerItem | PickerItemValue | null): PickerItem | null => {
    if (value === null) {
      //null
      return null;
    } else if (typeof value === "object" && value.hasOwnProperty("value") && value.hasOwnProperty("label")) {
      //PickerItem
      return value as PickerItem;
    } else {
      //PickerItemValue
      if (field && field.items && field.items.labels) {
        //items
        const index = field.items.values.indexOf(value as PickerItemValue);
        return {
          label: t(field.items.labels[index]),
          value: value as PickerItemValue
        } as PickerItem;
      } else if (field && field.hasOwnProperty("subModel") && dc) {
        //model
        const record = dc.GetRecord(value as PickerItemValue);
        if (record) {
          return {
            label: fieldFormat(record, (field as IFieldPickerModel).subModel.format, t),
            value: value as PickerItemValue
          } as PickerItem;
        } else {
          //should not be here
          return null;
        }
      } else {
        //should not be here
        return null;
      }
    }

    // let intValue: PickerItemValue;
    // if (value === null) {
    //   return null;
    // } else if (typeof value === "string") {
    //   intValue = value;
    // } else if (typeof value === "object" && value.hasOwnProperty("value") && value.hasOwnProperty("label")) {
    //   return value as PickerItem; //it's already option object
    // } else {
    //   intValue = value as PickerItemValue;
    // }

    // if (field && field.hasOwnProperty("subModel") && dc) {
    //   if (Array.isArray(value)) {
    //     return value.map((val) => {
    //       if (val && val.hasOwnProperty("value") && val.hasOwnProperty("label")) {
    //         return val as PickerItem; //it's already option object
    //       } else {
    //         const record = dc.GetRecord(val as PickerItemValue);
    //         if (record) {
    //           return {
    //             label: (field as IFieldPickerModel).subModel.format ? fieldFormat(record, (field as IFieldPickerModel).subModel.format, t) : t(record.label),
    //             value: val
    //           } as PickerItem;
    //         } else {
    //           return null;
    //         }
    //       }
    //     })
    //     .filter(x => x !== null) as PickerItem[];
    //   } else {
    //     const record = dc.GetRecord(intValue);
    //     if (record) {
    //       return {
    //         label: fieldFormat(record, (field as IFieldPickerModel).subModel.format, t),
    //         value: intValue
    //       } as PickerItem;
    //     } else {
    //       return null;
    //     }
    //   }
    // } else if (field && field.items && field.items.labels) {
    //   const index = field.items.values.indexOf(intValue);
    //   return {
    //     label: t(field.items.labels[index]),
    //     value: intValue
    //   } as PickerItem;
    // } else {
    //   return null;
    // }
  };

  const getFilteredItems = (bottom = false) => {
    const limit = bottom ? renderItems.length + offset : offset;
    const retItems = [];

    for (let i = 0; i < limit && i < items.length; ++i) {
      if (inputValue && items[i].label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
        retItems.push(items[i]);
      } else if (!inputValue) {
        retItems.push(items[i]);
      }
    }

    setRenderItems(retItems);
  };

  const refreshItems = () => {
    if (field && field.hasOwnProperty("subModel") && dc) {
      const idAttribute = dc.fieldId.source;

      dc.GetData().then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          const dcItems: Array<PickerItem> = data
            .map((r, i) => getItem(r.value))
            .filter((x) => x !== null) as PickerItem[];
          setItems(dcItems);
        } else {
          setItems([]);
        }
      });
    } else if (field && field.items && field.items.values) {
      const fieldItems = field.items.values.map((val) => {
        return getItem(val) as PickerItem;
      });
      setItems(fieldItems);
    } else {
      setItems([]);
    }
  };

  const isRequired = field && field.validation && field.validation.required ? true : false;

  const option: PickerItem | PickerItem[] | null = getItem(value);

  // TODO:
  // translations
  const loadingText = "Podaci se učitavaju...";
  const noDataText =
    field && field.hasOwnProperty("subModel") && (field as IFieldPickerModel).external
      ? "Počnite tipkati za pretragu"
      : "Nema podataka";

  const hasValue = option !== undefined && option !== null;
  const hasError = validation && validation.valid === false;
  const doShowHelper = field && field.hasOwnProperty("hideHelperText") ? (field.hideHelperText ? false : true) : true;
  const isReadOnly = controlMode === "view" || (field && field.readonly);
  const isLoading =
    dc ||
    (field && field.items && field.items.values) ||
    (field && field.hasOwnProperty("subModel") && (field as IFieldPickerModel).external)
      ? false
      : true;
  const hideLabel = formMode === "table";
  const label = t(field.ttoken);
  const placeholder = field && field.placeholder && field.placeholder.length ? field.placeholder : "";

  const allowAdd = field && field.hasOwnProperty("subModel") && (field as IFieldPickerModel).subModel.allowNew;

  const isMulti = false; // field && field.multi && Array.isArray(option);
  let displayValue = "";
  // if (isMulti) {
  //   const labels = option.map((op) => (op ? op.label : ""));
  //   displayValue = labels.join("; ");
  // }

  return isReadOnly ? (
    <TextField
      margin="none"
      fullWidth
      error={hasError}
      label={hideLabel ? null : label}
      placeholder={placeholder ? t(placeholder) : ""}
      id={field.source}
      value={isMulti ? displayValue : option ? (option as PickerItem).label : ""}
      InputProps={{
        readOnly: isReadOnly,
        endAdornment: isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" /> : null
      }}
      helperText={hasError ? t(validation.msg) : field.tooltip ? t(field.tooltip) : ""}
      variant="outlined"
      color="primary"
    />
  ) : (
    <Autocomplete
      disablePortal
      options={renderItems}
      value={option}
      getOptionLabel={(option: PickerItem) => option.label}
      renderInput={(params) => <TextField {...params} required={isRequired} label={t(label)} />}
      onChange={handleChange}
      loading={isLoading}
      loadingText={loadingText}
    />
  );
  {
    /* <FormControl margin="none" fullWidth error={hasError} variant="outlined">
      {formMode === "form" ? (
        <InputLabel
          shrink={hasValue || isFocused}
          required={isRequired}
          style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#424242" }}
          //className={isFocused ? classes.labelShrinkFocused : hasValue ? classes.labelShrink : null}
        >
          {t(label)}
        </InputLabel>
      ) : null}

      <SelectComponent
        value={option !== null ? option : null}
        placeholder=""
        inContainer={formMode}
        autoFocus={false}
        options={renderItems}
        noOptionsMessage={() => noDataText}
        isMulti={isMulti}
        isClearable={true}
        theme={theme}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onInputChange={handleInputChange}
        menuPosition="fixed"
        menuPlacement="auto"
        menuPortalTarget={document.body}
        onMenuScrollToBottom={() => getFilteredItems(true)}
        //styles={calculateStyles()}
        isLoading={isLoading}
        loadingMessage={() => loadingText}
        //onCreateOption={allowAdd ? handleCreateOption : null}
        formatCreateLabel={
          allowAdd
            ? (inputValue: string) => {
                return 'Dodaj "' + inputValue + '"';
              }
            : null
        }
        inputProps={{
          readOnly: isReadOnly,
          endAdornment: isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" /> : null
        }}
      />
      {doShowHelper ? (
        <FormHelperText id={field.source + "-helper"}>
          {hasError ? t(validation.msg) : field.tooltip ? field.tooltip : " "}
        </FormHelperText>
      ) : null}
    </FormControl>
    */
  }
}

export default PickerControl;
