import React from "react";

//Material-UI Core Components
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";

//Custom Components
import PublicPage from "@/ui/PublicPage/PublicPage";
import LoginForm from "@/views/Auth/LoginForm";

import model from "@/models/user";
import dataController from "@/lib/dataController";

function LoginPage() {

  const dc = new dataController(model)

  return (
    <PublicPage>
      <Box m={2}>
        <Container maxWidth={"sm"}>
          <LoginForm dc={dc} form="login" mode="update" onClose={() => {}} />
        </Container>
      </Box>
    </PublicPage>
  )
}

export default LoginPage;
