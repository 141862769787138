import React, { useEffect, useState } from 'react';

// Material-UI Core Components
import Input from "@mui/material/Input";

// Types
import { IIncrementControlProps } from "@/@types/controls/controls";

let isMulti = false;
const CTRL = 50;
const SHIFT = 10;

function IncrementControl(props: IIncrementControlProps) {

	const [minV, setMinV] = useState(0);
	const [maxV, setMaxV] = useState(999);
	const [stepV, setStepV] = useState(10);
	const { value, onChange } = props;

	useEffect(() => {
		const { min, max, step } = props;
		if (typeof min === 'number' && min !== undefined) setMinV(min);
		if (typeof min === 'number' && max !== undefined) setMaxV(max);
		if (typeof min === 'number' && typeof step === 'number' 
			&& step !== 0 && step !== undefined) setStepV(step); // dont allow zeroes
	}, []);

	const checkBounds = (v: number) => {
		// const v = parseInt(val, 10);
		return isNaN(v) || v < minV ? 
			minV
			: v > maxV ? maxV
			: v;
	}

	// round v to nearest divisor of b
	const r2n = (v: number, b: number = 10) => {
		/*
			e.g. v = 17, b = 10 (b >> 1 is 5)
			return 20
			r = 7, r >= 5, return v + b - r = 20
			e.g. v = 116, b = 50 (b >> 1 is 25)
			r = 16, r < 25, return v - r = 100
		*/
		const r = v % b; // remainder, v = k * b + r (for some k in Z)
		return r < (b >> 1) ? // if remainder is less than half of the base
			v - r 							// round down
			: v + (b - r);			// round up
	}

  const handleBlur = () => {
  	const { onChange } = props;
  	const v = checkBounds(value);
  	if (v !== value) {
	  	onChange(v);
  	}
  };

  const handleChange = (evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  	const { onChange } = props;
  	//@ts-ignore // TODO fix this event
  	const { type, keyCode, shiftKey, ctrlKey } = evt;
  	if (type !== "keydown" && isMulti) {
  		isMulti = false;
  		return;
  	}
  	if (type === "keydown") {
  		const i = keyCode === 40 ? -1 : keyCode === 38 ? 1 : 0;
  		const v = ctrlKey ? (value + i * CTRL) : (value + i * SHIFT);
  		if (v !== value) {
  			isMulti = true;
  			onChange(checkBounds(r2n(v, ctrlKey ? CTRL : SHIFT)));
  		}
  	} else {
  		if (evt && evt.target && (evt.target as HTMLInputElement).value) {
		  	const v = parseInt((evt.target as HTMLInputElement).value, 10);
		  	onChange(checkBounds(v));
  		}
  	}
  }

	return (
		<Input 
			value={value}
			onChange={handleChange}
			onKeyDown={handleChange}
			onBlur={handleBlur}
			margin="dense"
			inputProps={{
				type: "number",
				min: minV,
				max: maxV,
				step: stepV
			}}
			style={{ maxWidth: "45px" }}
		/>
	);
}

export default IncrementControl;