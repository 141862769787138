import { IModel } from "@/@types/models/model";
import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";

const Kablovi: IModel = {
  title: "Kablovi",
  apiPath: "data/kablovi",
  forms: {
    "default": {
      fields: ["id", "kc", "ko_mb", "ko_ime", "duzina"]
    }
  },
  listViews: {
    "default": {
      fields: ["id"]
    }
  },
  fields: [
    coreFields.id,
    commonFields.kc,
    commonFields.ko_mb,
    commonFields.ko_ime,
    commonFields.duzina
  ]
}

export default Kablovi;