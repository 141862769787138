import { FunctionComponent, useContext, useEffect } from "react";
import clsx from "clsx";

//OpenLayers
import { ZoomSlider } from "ol/control";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlControl } from "@/lib/olHelpers";
import makeMUIControlStyle from "../makeMUIControlStyle";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IZoomSliderControl } from "@/@types/components/Map/Controls/OpenLayers";

const ZoomSliderControl: FunctionComponent<IZoomSliderControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;
  const classes = makeMUIControlStyle();

  const options = {
    className: clsx(classes.control, "ol-zoomslider"),
    duration: undefined,
    render: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, ZoomSlider, props, options, events), [
    props.className,
    props.duration,
    props.render,
    classes.control
  ]);

  return null;
};

export default ZoomSliderControl;

