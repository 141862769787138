import React, { useContext, FunctionComponent, ComponentClass } from "react";
import { Navigate } from "react-router-dom";

import UserContext from "@/components/UserContext/UserContext";
import Loader from "@/ui/Loader/Loader";
import { authService } from "@/services/authService";

const AUTH_TOKEN = "auth_token";

type PrivatePageProps = {
  children: ComponentClass<any,any> | FunctionComponent<any>
}

const PrivatePage = (props: PrivatePageProps) => {
  const userContext = useContext(UserContext);

  const accessToken = localStorage.getItem(AUTH_TOKEN);
  const hasAccessToken = accessToken !== null && accessToken.length > 0 ? true : false;

  const knowsWhoAmI = userContext !== null && userContext.username !== null && userContext.username.length > 0 ? true : false;
  const isAuthenticated = hasAccessToken && knowsWhoAmI;

  if (hasAccessToken && !knowsWhoAmI) {
    authService
      .whoAmI()
      .then((user) => {
        if (user === undefined) {
          localStorage.removeItem(AUTH_TOKEN);
          window.location.reload();
        } else {
          if (userContext !== null) {
            userContext.setUserData(user);
          }
        }
      })
      .catch((err) => {
        console.warn(err);
        localStorage.removeItem(AUTH_TOKEN);
        window.location.reload();
      })
  }

  if (isAuthenticated) {
    return React.createElement(props.children);
  } else if (hasAccessToken && !knowsWhoAmI) {
    return <Loader open={true} />;
  } else {
    return <Navigate to={{ pathname: "/login" }} />;
  }
};

export default PrivatePage;
