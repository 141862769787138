import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

// Material-UI Components
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { MultiFilterWrapperFn } from "@/@types/ui/Table";
import { PickerItem } from "@/@types/controls/controls";

export const MultiColumnFilter: MultiFilterWrapperFn =
(setFilterLocalStorage, subModelsWithData) => ({column: {filterValue, setFilter, id}}) => {
  const { t } = useTranslation();

  const options = React.useMemo(() => {
    if (subModelsWithData) {
      if(subModelsWithData[id]) {
        const { records } = subModelsWithData[id];
        const opts = new Set(records);
        return Array.from(opts.values()) as Array<PickerItem>;
      }
      return [];
    }
    return [];
  }, [id, subModelsWithData]);

  return (
    <Select
      value={Array.isArray(filterValue) ? filterValue : []}
      onChange={(e, value) => {
        const _selected = Array.isArray(value) ?
          value.map((opt) => parseInt(opt as string))
          : value;
        setFilterLocalStorage(id, _selected || undefined, setFilter);
      }}
      multiple
      variant="outlined"
      margin="dense"
    >
      {
        options.map((option, i) => (
          <MenuItem key={i} value={/*@Piero this looks hacky*/String(option.value)}>
            {t(option.label)}
          </MenuItem>
        ))
      }
    </Select>
  );
}
