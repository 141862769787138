import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";

import { ConfirmResult } from "@/@types/lib/confirmDialog";

export default function confirm(
  title: string,
  text: string,
  type: string,
  confirmButtonText: string,
  cancelButtonText: string
): Promise<ConfirmResult> {
  return Swal.fire({
    title: title,
    text: text,
    icon: type,
    showCancelButton: true,
    cancelButtonText: cancelButtonText,
    confirmButtonText: confirmButtonText,
    allowOutsideClick: false,
    allowEscapeKey: true,
    keydownListenerCapture: true,
    showCloseButton: true,
    onClose: Swal.close()
  } as SweetAlertOptions)
    .then((result: SweetAlertResult) => {
      Swal.close();
      if (result.value) {
        return Promise.resolve({ confirmed: true } as ConfirmResult);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return Promise.reject({ confirmed: false } as ConfirmResult);
      } else {
        return Promise.reject({ canceled: true } as ConfirmResult);
      }
    })
    .catch((err) => {
      return Promise.reject({ error: true });
    });
}
