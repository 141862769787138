import * as React from "react";
import { FunctionComponent } from "react";
import Draggable from "react-draggable";

//MUI
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";

//Types
import { IDraggableDialog } from "@/@types/ui/Dialog";
import { PaperProps } from "@mui/material/Paper";

function DragComponent(props: PaperProps) {
  return (
    <Draggable handle={".dialogTitle"} cancel={".nodrag"}>
      <Paper {...props} />
    </Draggable>
  );
}

const DraggableDialog: FunctionComponent<IDraggableDialog> = (props) => {
  const { fixedHeight, ...rest } = props;

  return (
    <Dialog
      PaperComponent={DragComponent}
      fullWidth={true}
      sx={{
        minHeight: fixedHeight ? "90vh" : "30vh",
        maxHeight: "90vh"
      }}
      {...rest}
    >
      {props.children}
    </Dialog>
  );
};

export default DraggableDialog;
