import LoginPage from "@/views/Auth/LoginPage";
import Registration from "@/views/Auth/RegistrationPage";
import PasswordResetRequestPage from "@/views/Auth/PasswordResetRequestPage";
import PasswordResetPage from "@/views/Auth/PasswordResetPage"

//Types
import { RouteType } from "@/@types/common";

const publicRoutes: RouteType[] = [
	{ path: "/login", name: "Login", component: LoginPage },
	{ path: "/register", name: "Register", component: Registration},
  { path: "/password-reset-request", name: "Password Reset Request", component: PasswordResetRequestPage },
  { path: "/password-reset", name: "Reset Password", component: PasswordResetPage }
];

export default publicRoutes
