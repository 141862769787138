import { createContext } from "react";

//Types
import { MapContextType } from "@/@types/context/MapContext";

const MapContext = createContext<MapContextType | null>(null);

MapContext.displayName = "MapContext";
export default MapContext;

