import * as React from "react";
import { FunctionComponent } from "react";

//Material UI Core Elements
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import { grey } from "@mui/material/colors";

//Material UI Icons
import PublicIcon from '@mui/icons-material/Public';

//Custom Components
import StatusRoot from "./StatusRoot";

//Types
import { CoordinateSystemType, IStatusControl } from "@/@types/components/Map/Controls/Status";

const StatusControl: FunctionComponent<IStatusControl> = (props) => {
  const { changeCoordinateSystem } = props;

  const fontSize = 14;

  const genIcon = (type: CoordinateSystemType) => {
    const defaultStyles = {
      size: "small",
      fontSize: fontSize,
      borderRadius: "50%",
      margin: "0px 5px 0px 2px",
      cursor: "pointer"
    };
    return (
      <Tooltip
        title={type}
        placement="top"
      >
        <PublicIcon
          style={{
            color: "#b7c800",
            backgroundColor: grey[100], ...defaultStyles
          }}
          onClick={() => changeCoordinateSystem(type)}
        />
      </Tooltip>
    );
  }


  return (
    //@ts-ignore TODO: Property 'ref' is missing in type '{ children: Element; }' but required in type 'IStatusRoot', not sure where ref is taken from
    <StatusRoot>
      <Box m={0} style={{
        padding: "1px 5px 1px 5px",
        verticalAlign: "middle"
      }}>
        {genIcon("HTRS96/TM")}
        {genIcon("WGS84")}
      </Box>
    </StatusRoot>
  );
};

export default StatusControl;

