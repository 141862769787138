import * as React from "react";

//Custom Controls
import BaseInputControl from "./BaseInputControl";

//Types
import { IWholeNumberControlProps } from "@/@types/controls/controls";

function WholeNumberControl(props: IWholeNumberControlProps) {
  const { formMode, controlMode, field, value, onChange, helperText, validation, ...other } = props;

  const tryParseInt = (str: string, defaultValue: number | null) => {
    let ret = defaultValue;
    if (str !== null && str !== undefined) {
      if (str.length > 0 && str.match(/^\d+$/) !== null) {
        try {
          ret = parseInt(str, 10);
          return ret;
        }
        catch {
          return null;
        }
      }
    }
    return ret;
  };

  const handleChange = (newValue: string | number | null, source: string) => {
    if (typeof newValue === "string") {
      if (newValue.length > 0) {
        const num = tryParseInt(newValue, null);
        if (num !== null) {
          onChange(num, source);
        }
      } else {
        onChange(null, source);
      }
    } else if (typeof newValue === "number") {
      onChange(newValue, source);
    } else if (newValue === null || newValue === undefined) {
      onChange(null, source);
    }
  };

  const displayValue = value !== null ? value.toString() : "";

  return (
    <BaseInputControl
      {...props}
      type="number"
      value={displayValue}
      onChange={handleChange}
    />
  );
}

export default WholeNumberControl;
