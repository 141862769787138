import React, { FunctionComponent, useContext } from "react";

//Custom components
import SidebarContext from "./SidebarContext/SidebarContext";

const SidebarTabList: FunctionComponent<any> = (props) => {
  const sidebarContext = useContext(SidebarContext);

  return (
    <ul
      role="tablist"
      style={{
        position: "absolute",
        width: 40,
        margin: 0,
        padding: 0,
        listStyleType: "none",
        left: sidebarContext?.position === "left" ? 0 : undefined,
        right: sidebarContext?.position === "right" ? 0 : undefined
      }}
    >
      {props.children}
    </ul>
  );
};

export default SidebarTabList;
