import React, { useContext } from "react";

import AppPage from "@/ui/AppPage/AppPage";
import MainMap from "./MainMap";

import UserContext from "@/components/UserContext/UserContext";

function MapPage() {
  const userContext = useContext(UserContext);

  return (
    <AppPage>
      {userContext?.loaded ?
        <MainMap />
        : null
      }
    </AppPage>
  );
}

export default MapPage;
