import * as React from "react";
import { useState, useRef } from "react";
import moment from 'moment';
import { useTranslation } from "react-i18next";

//Material UI
// import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

//Material-UI Core Components
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from '@mui/material/IconButton';
import Popover from "@mui/material/Popover";

//Material-UI Icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LockIcon from "@mui/icons-material/Lock";
import { OutlinedInput } from "@mui/material";

//Custom Components
import { formats } from "@/lib/formats";
import Datetime from "react-datetime";
import { IDateTimeControlProps } from "@/@types/controls/controls";

// import TimeLeft from 'UI/TimeLeft';
// TODO:
// switch to material-UI DateTime picker (https://material-ui-pickers.dev/demo/datetime-picker)
// additional functionalities:
//    today button - with jump to today
//    there exists range picker but only for week on their page - it is probably possible to extend the functionality to pick the range on calendar

// const labelStyles = makeStyles((theme) => ({
//   label: {
//     backgroundColor: theme.palette.background.default,
//     marginLeft: "26px"
//   },
//   labelShrinkFocused: {
//     backgroundColor: theme.palette.background.default,
//     color: theme.palette.primary.main,
//     padding: "0 6px"
//   },
//   labelShrink: {
//     backgroundColor: theme.palette.background.default,
//     padding: "0 6px",
//     color: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.72)"
//   }
// }));

function DateTimeControl(props: IDateTimeControlProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [inputValue, setInputValue] = useState<string | null>(null);

  const inputRef = useRef();
  const { field, value, onChange, validation, hideTime, formMode, controlMode } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  // const classes = labelStyles();

  const showTime = hideTime === undefined || hideTime === false ? true : false;

  const handleSelectDate = (value: moment.Moment | string) => {
    setInputValue(null);

    if (typeof value === "string") {
      const mom = moment.utc(value).local()
      applyDate(mom);

    } else if (moment.isMoment(value)) {
      if (showTime === false) {
        value = value.set("hour", 12);
      }

      applyDate(value.local().toISOString());
    }
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.currentTarget.value;
    setInputValue(value);
  };

  const handleOpen = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
  };

  const handleFocusLost = (evt: React.FocusEvent<HTMLInputElement>) => {
    if (inputValue === "") {
      applyDate(null);
    } else if (inputValue !== undefined) {
      let mom = moment(inputValue, formats.supported_date_formats);
      if (mom.isValid()) {
        if (showTime === false) {
          mom = mom.set("hour", 12);
        }
        applyDate(mom.utc().toISOString());
      }
    }

    setIsFocused(false);
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === "Enter") {
      applyDate(evt.currentTarget.value);
    }
  };

  const getIsValidFunction = () => {
    return undefined;

    //TODO: refactor
    // let fnc;

    // if (field.dateFilter) {
    //   if (field.dateFilter.scope === "today") {
    //     const todayDate = moment().subtract(1, "day");
    //     fnc = function (current) {
    //       return field.dateFilter.clause === "isBefore" ? current.isAfter(todayDate) : current.isBefore(todayDate);
    //     };
    //   } else {
    //     const checkDate = moment(field.dateFilter.scope).subtract(1, "day");
    //     fnc = function (current) {
    //       return field.dateFilter.clause === "isBefore" ? current.isBefore(checkDate) : current.isAfter(checkDate);
    //     };
    //   }
    // }
    // return fnc;
  };

  const applyDate = (value: string | moment.Moment | null) => {
    let resultValue: moment.Moment | null = null;

    if (value === undefined || value === null) {
      resultValue = null;
    } else if (typeof value === "string") {
      if (value === "") {
        resultValue = null;
      } else {
        const mom = moment.utc(value, formats.supported_date_formats);
        if (mom.isValid()) {
          // resultValue = mom.utc().toISOString();
          resultValue = mom.utc();
        } else {
          resultValue = null;
        }
      }
    } else {
      // resultValue = value.utc().toISOString();
      resultValue = value.utc();
    }

    setInputValue(null);

    //console.log('apply', resultValue);
    if (onChange) {
      onChange(resultValue, field.source);
    }
  };

  const formatDate = (value: moment.Moment | null) => {
    const mom = moment.utc(value);
    //if (value && moment.isMoment(value) && value.isValid()) {
    if (mom.isValid()) {
      //const mom = moment.utc(value, formats.supported_date_formats);
      const format = showTime ? formats.datetime : formats.date;
      mom.local();
      const res = mom.format(format);
      return res;
    } else {
      return "";
    }
  };

  const isRequired = field.validation && field.validation.required;

  const hasValidFnc =
    field.validation !== undefined &&
    (field.validation.hasOwnProperty("maxDate")
      || field.validation.hasOwnProperty("isAfter")
      || field.validation.hasOwnProperty("isBefore")
    );
  const filterFunction = hasValidFnc ? getIsValidFunction() : undefined;

  const format = showTime ? formats.datetime : formats.date;
  const label = t(field.ttoken);

  const inputValueExist = inputValue !== null && inputValue !== undefined && inputValue !== "";
  //const hasValue = inputValueExist || (value && moment.isMoment(value) && value.isValid());
  const hasValue = inputValueExist || moment(value).isValid();
  const hasError = validation && validation.valid === false;

  const isReadOnly = controlMode === "view" || field.readonly;

  return (
    <FormControl margin="none" fullWidth required={isRequired} error={hasError} variant="outlined">
      {formMode === "form" ? (
        <InputLabel
          shrink={hasValue || isFocused}
          required={isRequired}
          // className={[
          //   classes.label,
          //   isFocused ? classes.labelShrinkFocused : hasValue ? classes.labelShrink : undefined
          // ].join(" ")}
          style={{
            color: isFocused && hasValue ? theme.palette.primary.main : undefined,
            backgroundColor: theme.palette.mode === "light" ? theme.palette.background.default : "#424242"
          }}
        >
          {t(label)}
        </InputLabel>
      ) : null}
      <OutlinedInput
        value={inputValueExist ? inputValue : formatDate(value)}
        placeholder={format}
        color="primary"
        fullWidth
        readOnly={isReadOnly}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleFocusLost}
        onKeyDown={handleKeyDown}
        startAdornment={
          <InputAdornment position="start">
            <IconButton aria-label="open calendar" onClick={handleOpen} size="small" disabled={isReadOnly}>
              <CalendarTodayIcon style={{ fontSize: 20 }} color={isReadOnly ? "disabled" : "primary"} />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <div>
            {/*showTimeLeft ?
                <InputAdornment position="end">
                  <TimeLeft config={config} deadline={value} format="chip" showText={false} />
                </InputAdornment>
                : null
              */}
            {isReadOnly ? <LockIcon style={{ fontSize: 20 }} color="disabled" /> : null}
          </div>
        }
        ref={inputRef}
      />
      <FormHelperText>{hasError ? t(validation.msg) : field.tooltip ? field.tooltip : " "}</FormHelperText>
      <Popover
        id={"calendar"}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <Datetime
          utc={false}
          isValidDate={filterFunction}
          locale="hr"
          value={moment.utc(value).local()}
          timeFormat={showTime ? "HH:mm" : false}
          onChange={handleSelectDate}
          //className={classes.container}
          input={false}
          open={true}
        />
      </Popover>
    </FormControl>
  );
}

export default DateTimeControl;
