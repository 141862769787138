import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

//Material-UI Core Components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";

//Custom Components
import { GridContainer, GridItem } from "@/ui/Grid";
import FormButton from "@/ui/Form/FormButton";
import FormContent from "@/components/FormContent";
import UserContext from "@/components/UserContext/UserContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import useAuthFormController from "@/components/useAuthFormController";

//Types
import { PasswordResetFormProps } from "@/@types/views/Auth";

function PasswordResetForm(props: PasswordResetFormProps) {
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);

  let navigate = useNavigate();

  const { record, fields, validation, onFieldChange, doResetPassword } = useAuthFormController(props);

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleSubmit = () => {
    if (doResetPassword) {
      doResetPassword()
        .then((result) => {
          if (result) {
            if (result.success) {
              //TODO: Send userdata
              //userContext?.setUserData(result.user);
              if (snackbarContext) {
                snackbarContext.showNotification(t("users.password_set"), "success");
              }
              navigate("/login");
            }
          }
        })
        .catch((result) => {
          if (result.validationPass === false) {
            console.log(result.validation);
          } else if (snackbarContext) {
            snackbarContext.showNotification(result.error as string, "error");
          }
        });
    }
  };

  let lbl_submit = t("buttons.confirm");
  return (
    <Card>
      <CardContent>
        <GridContainer>
          <GridItem xs={12}>
            <Typography variant="h4" component="h2">
              {t("titles.password_reset")}
            </Typography>
          </GridItem>
          <FormContent
            fieldNames={["email", "passwordreset_token", "password_new", "password_new_confirm"]}
            record={record}
            fields={fields}
            mode={"insert"}
            columns={1}
            validation={validation}
            onFieldChange={handleFieldChange}
            onSubmit={handleSubmit}
          />
        </GridContainer>
      </CardContent>
      <CardActions>
        <FormButton color="primary" variant="contained" onClick={handleSubmit}>
          {lbl_submit}
        </FormButton>
      </CardActions>
    </Card>
  );
}

export default PasswordResetForm;
