import * as React from "react";
import { FunctionComponent, useContext, useEffect } from "react";

//OpenLayers ext
import Bar from "ol-ext/control/Bar";
import "ol-ext/control/Bar.css";

//OpenLayers
import Control from "ol/control/Control";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";
import BarControlContext from "./BarControlContext";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IBarControl } from "@/@types/components/Map/Controls/OpenLayersExt/Bar";

const BarControl: FunctionComponent<IBarControl> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;

  const { position } = props;

  const childControls: Control[] = [];

  const options = {
    className: undefined,
    group: undefined,
    toggleOne: undefined,
    autoDeactivate: undefined,
  };

  const events = {};

  useEffect(() => {
    // console.log('bar useEffect');
    let allOptions = Object.assign(options, props);
    let definedOptions = getDefinedOptions(allOptions);

    let control = new Bar(definedOptions);
    control.setPosition(position || "top");

    childControls.forEach(child => {

      control.addControl(child);
    })

    if(props.id) {
      control.set("id", props.id)
    }

    if (mapContext.map) {
      const mapControl = findControl(mapContext.map, props.id, Bar);
      if (mapControl) {
        mapContext.map.removeControl(mapControl);
      }
      mapContext.map.addControl(control);
    } else {
      mapContext.initOptions.controls.push(control);
    }

    let olEvents = getEvents(events, props);
    for (let eventName in olEvents) {
      //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id,  Bar);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    }


  }, [
    props.className,
    props.group,
    props.toggleOne,
    props.autoDeactivate,
    props.lng
  ])

  if (props.children === undefined) {
    return null;
  } else {
    return (
      <BarControlContext.Provider value={{ exists: true, childControls: childControls }} >
        {props.children}
      </BarControlContext.Provider>
    );
  }
};

export default BarControl;

