import * as React from "react";
import { useEffect, useContext, useRef, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
const classNames = require('classnames'); //TODO: compiler cannot find declaration file for classNames yet it is deprecated in @types/classNames

//OpenLayers
import Overlay from 'ol/Overlay';
import Tooltip from "ol-ext/overlay/Tooltip";
import Toggle from "ol-ext/control/Toggle";
import Bar from "ol-ext/control/Bar";
import "ol-ext/control/Bar.css";
import { Draw as OlInteractionDraw } from "ol/interaction";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IMeasureControl } from "@/@types/components/Map/Controls/Custom";

const MeasureControls: FunctionComponent<IMeasureControl> = (props) => {
  const { t } = useTranslation();

  const context = useContext(MapContext) as MapContextType;

  let tooltipRef = useRef(null);

  const { position, onAddFeature, target, id } = props;

  const options = {
    target: undefined
  };

  const events = {
    "change:active": undefined,
    "change:disabled": undefined,
  };

  const getMidPoint = (coordArray: number[][]): number[] => {
    const len = coordArray.length - 1;
    let coordX = 0, coordY = 0;
    coordArray.slice(0, len).forEach((coordPair) => {
      coordX += coordPair[0];
      coordY += coordPair[1];
    });

    return [coordX / len, coordY / len];
  }

  const saveFeature = (drawEvent: any, tooltip: any): void => {
    if (drawEvent && drawEvent.feature && drawEvent.feature.getGeometry() && drawEvent.feature.getGeometry().getLastCoordinate()) {
      const measureValDiv = document.getElementById("measure-overlay");
      if(context.map && measureValDiv){
        measureValDiv.innerHTML = tooltip.prevHTML;
        measureValDiv.style.display = "block";
        measureValDiv.style.minWidth = "150px";

        let coordinates = drawEvent.feature.getGeometry().getLastCoordinate();
        if (drawEvent.feature.getGeometry().getType() === "Polygon") {
          coordinates = getMidPoint(drawEvent.feature.getGeometry().getCoordinates()[0]);
        }
        const measureVal = new Overlay ({
          position: coordinates,
          positioning: 'center-left',
          element: measureValDiv
        });
        context.map.addOverlay(measureVal);
      }
    }

    //@ts-ignore TODO: This condition will always return true since this function is always defined. Weird TS error that doesn't take into account drawEvent
    if (onAddFeature && drawEvent.type === "drawend" && drawEvent.feature) {
      onAddFeature(drawEvent.feature);
    }
    tooltip.removeFeature.bind(tooltip)(drawEvent);
  }

  useEffect(() => {
    if (context.map && tooltipRef) {
      //@ts-ignore TODO:  Type 'Tooltip' is not assignable to type 'null'
      tooltipRef.current = new Tooltip({
        positionning: 'center-left'
      });
      //@ts-ignore TODO: Argument of type 'null' is not assignable to parameter of type 'Overlay'
      context.map.addOverlay(tooltipRef.current);
    }
  }, [ context.map, tooltipRef ])

  useEffect(() => {
    let allOptions = Object.assign(options, props);
    let definedOptions = getDefinedOptions(allOptions);

    const drawLineInteraction = new OlInteractionDraw({ type: 'LineString' })
    const drawAreaInteraction = new OlInteractionDraw({ type: 'Polygon' })

    const isInSidebar = target !== undefined;

    const mLineIconElement = document.createElement('i');
    mLineIconElement.className = classNames('fas', 'fa-ruler',{'fa-2x': isInSidebar});
    //@ts-ignore TODO: dataToggle does not exist on type 'HTMLElement'
    mLineIconElement.dataToggle = "tooltip";
    mLineIconElement.title = t("map.length");

    const mAreaIconElement = document.createElement('i');
    mAreaIconElement.className = classNames('fas', 'fa-draw-polygon',{'fa-2x': isInSidebar});
    //@ts-ignore TODO: dataToggle does not exist on type 'HTMLElement'
    mAreaIconElement.dataToggle = "tooltip";
    mAreaIconElement.title = t("map.area");

    let control = new Bar({
      ...definedOptions,
      className: classNames({"ol-bar-sidebar": isInSidebar}),
      controls:[
        new Bar({
          toggleOne: true,
          controls: [
            new Toggle({
              className: classNames("ol-measure-line", {"ol-thumb-control": isInSidebar}),
              html: mLineIconElement.outerHTML,
              interaction: drawLineInteraction
            }),
            new Toggle({
              className: classNames("ol-measure-area", {"ol-thumb-control": isInSidebar}),
              html: mAreaIconElement.outerHTML,
              interaction: drawAreaInteraction
            }),
          ],
        })
      ]
    })

    control.setPosition(position || "top");

    if (id) {
      control.set("id", id);
    }

    if (context.map && tooltipRef && tooltipRef.current) {
      const tooltip: Tooltip = tooltipRef.current;
      // Set feature on drawstart
      drawLineInteraction.on('drawstart', tooltip.setFeature.bind(tooltip));
      // Remove feature on finish
      drawLineInteraction.on(['change:active','drawend'], (drawEvent) => saveFeature(drawEvent, tooltip));

      // Set feature on drawstart
      drawAreaInteraction.on('drawstart', tooltip.setFeature.bind(tooltip));
      // Remove feature on finish
      drawAreaInteraction.on(['change:active','drawend'], (drawEvent) => saveFeature(drawEvent, tooltip));

      // console.log('map add')
      const mapControl = findControl(context.map, id, Bar);
      if (mapControl) {
        context.map.removeControl(mapControl);
        // console.log("control removed", Button);
      }
      context.map.addControl(control);
      // console.log("control added", Button);
    } else {
      // console.log('initOptions add')
      context.initOptions.controls.push(control);
    }

    let olEvents = getEvents(events, props);
    for (let eventName in olEvents) {
      //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (context.map) {
        const mapControl = findControl(context.map, id, Bar);
        if (mapControl) {
          context.map.removeControl(mapControl);
        }
      }
    };
  }, [t]);

  return null;
};

export default MeasureControls;

