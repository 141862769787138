import { IModel } from "@/@types/models/model";

const User: IModel = {
  title: "Users",
  apiPath: "data",
  forms : {
    "default": {
      fields: [ "id", "objectid", "opis"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "objectid", "opis"]
    }
  },
  fields: [
    {
      title: "id",
      source: "id",
      ttoken: "common.id",
      type: "numeric",
      idattr: true,
      readonly: true
    },
    {
      title: "objectid",
      source: "objectid",
      ttoken: "common.objectid",
      type: "numeric",
      readonly: true
    },
    {
      title: "opis",
      source: "opis",
      ttoken: "common.opis",
      type: "text",
      readonly: true
    }
  ]
}

export default User;
