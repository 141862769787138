import * as React from "react";
import { Fragment, FunctionComponent } from "react";

//MUI
import Toolbar from "@mui/material/Toolbar";

//Custom Components
import PublicHeader from "@/ui/Header/PublicHeader";
import Footer from "@/ui/Footer/Footer";

//Background Image
import Image from "@/images/bkg/background.jpg";

//Types
import { IPublicPage } from "@/@types/ui/PublicPage";

const PublicPage: FunctionComponent<IPublicPage> = (props) => {
  return (
    <Fragment>
      <PublicHeader />
      <main id="content-wrapper" style={{ flexGrow: 1 }}>
        <Toolbar variant="dense" />
        <div
          id="content"
          style={{
            backgroundImage: `url(${Image})`,
            objectFit: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover"
          }}
        >
          {props.children}
        </div>
      </main>
      {/*<Footer />*/}
    </Fragment>
  );
};

export default PublicPage;
