import React from "react";

//Custom Components
import AppPage from "@/ui/AppPage/AppPage";
import CesiumPage from "@/views/ThreeD/CesiumPage";

function ThreeDPage() {

  return (
    <AppPage>
      <CesiumPage/>
    </AppPage>
  )

}

export default ThreeDPage;