import { createContext } from 'react';

//Types
export type NavDrawerContextType = {
    sidebarOpen: boolean
    toggleSidebar: () => void
}

const NavDrawerContext = createContext<NavDrawerContextType>({ sidebarOpen: false, toggleSidebar: () => { } });
NavDrawerContext.displayName = 'NavDrawerContext';

export default NavDrawerContext
