import React, { CSSProperties, useContext } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Button from "@mui/material/Button";

//Custom Components
import UserContext from "@/components/UserContext/UserContext";

//Types
import { UserContextType } from "@/@types/context/UserContext";
import { IMapCardButtonProps } from "@/@types/ui/MapCard";

// const useTooltipStyles = makeStyles((theme) => ({
//   tooltip: {
//     color: "white",
//     backgroundColor: theme.palette.primary.main,
//     fontSize: 16
//   }
// }));


function MapCardActionButton(props: IMapCardButtonProps) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext) as UserContextType;

  const { needPermission, tooltip, disabled, stickyRight, ...rest } = props;

  const divStyle: CSSProperties = {};
  if (stickyRight) {
    divStyle["marginLeft"] = "auto";
  }

  return needPermission && !userContext.hasAnyPermission(needPermission) ? null : (
    // <ToolTip arrow={true} classes={tooltipClasses} title={t("buttons."+tooltip)} PopperComponent={MapCardTooltipPopper}>
      <div style={divStyle}>
        <Button size="small" color="primary" variant="contained" {...rest}  disabled={disabled ? disabled : false} sx={{mr: 1}}>
          {props.children}
        </Button>
      </div>
    // </ToolTip>
      // Privremeno sam izkomentiro tooltip, ne vidim svrhu tooltip za dodatnu info kad na gumbic vec pise sta je akcija
      // a sto ti znas?
  );
}

export default MapCardActionButton;
