import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { IFieldText } from "@/@types/models/model";

//Material-UI Core Components
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";

//Material-UI Icons
import VpnKeyIcon from "@mui/icons-material/VpnKey";

//Custom Components
import dataController from "@/lib/dataController";
import model from "@/models/user";
import AppPage from "@/ui/AppPage/AppPage";
import GridContainer from "@/ui/Grid/GridContainer";
import GridItem from "@/ui/Grid/GridItem";
import TextControl from "@/controls/TextControl";
import FormButton from "@/ui/Form/FormButton";
import PasswordChangeDialog from "@/views/ProfilePage/PasswordChangeDialog";

import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import DialogContext from "@/ui/DialogContext/DialogContext";
import UserContext from "@/components/UserContext/UserContext";

function ProfilePage() {
  const userContext = useContext(UserContext);
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const dc = new dataController(model);

  const handlePasswordChange = () => {
    dialogContext.showDialog(PasswordChangeDialog, {
      dc: dc,
      form: "password_change"
    });
  };

  return (
    <AppPage>
      <Box m={2}>
        <Container maxWidth="sm">
          <Card>
            <CardContent>
              <GridContainer>
                <GridItem xs={12}>
                  <Typography variant="h5" component="h3">
                    {t("titles.user_profile")}
                  </Typography>
                </GridItem>
                <GridItem xs={12}>
                  <TextControl
                    formMode="table"
                    controlMode="view"
                    value={userContext?.username ? userContext.username : ""}
                    label="Username"
                    field={dc.getField("username") as IFieldText}
                    onChange={() => {}}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextControl
                    formMode="table"
                    controlMode="view"
                    value={userContext?.email ? userContext.email : ""}
                    label="Email"
                    field={dc.getField("email") as IFieldText}
                    onChange={() => {}}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextControl
                    formMode="table"
                    controlMode="view"
                    value={userContext?.roleId ? userContext.roleId.toString() : ""}
                    label="Status"
                    field={dc.getField("status") as IFieldText}
                    onChange={() => {}}
                  />
                </GridItem>
              </GridContainer>
            </CardContent>
            <CardActions>
              <FormButton variant="contained" onClick={handlePasswordChange} startIcon={<VpnKeyIcon />}>
                {t("buttons.password_change")}
              </FormButton>
            </CardActions>
          </Card>
        </Container>
      </Box>
    </AppPage>
  );
}

export default ProfilePage;
