import React, { FunctionComponent, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

//MUI
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

//MUI Icons
import Icon from "@mui/material/Icon";

//Custom Components
import UserContext from "@/components/UserContext/UserContext";
import NavDrawerContext from "@/components/NavDrawerContext/NavDrawerContext";

//Types
import { MenuType, LocationType, MatchType } from "@/@types/common";
import { UserContextType } from "@/@types/context/UserContext";

// import Logo from "@/images/holcim_logo_color.png";

export interface INavDrawer {
  menu: MenuType[]
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  minHeight: 48,
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  // borderBottom: "2px",
  borderStyle: "solid",
  borderColor: theme.palette.primary.main
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

const NavDrawer: FunctionComponent<INavDrawer> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;
  const navDrawerContext = useContext(NavDrawerContext);
  const theme = useTheme();
  const { menu } = props;
  const { sidebarOpen, toggleSidebar } = navDrawerContext;

  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    toggleSidebar();
  };

  const handleDrawerClose = () => {
    toggleSidebar();
  };

  const isActiveFn = (match: MatchType | null, location: LocationType) => {
    if (!match) return false;
    // console.log(match.url === location.pathname, match.url, location.pathname);
    return match.url === location.pathname;
  };

  return (
    <aside id="nav-drawer">
      <Drawer variant="permanent" open={sidebarOpen}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List disablePadding>
          {menu.map((m: MenuType, i: number) => {
            if (m.needPermission !== undefined && !userContext.hasAnyPermission(m.needPermission)) {
              return null;
            } else {
              return (
                <ListItem
                  key={i}
                  button
                  component={React.forwardRef<HTMLAnchorElement>(({ children, ...props }, ref) => (
                    <NavLink
                      {...props}
                      ref={ref}
                      to={m.path}
                      end
                    //isActive={(match, location) => isActiveFn(match, location)}
                    >
                      {children}
                    </NavLink>
                  ))}
                >
                  <ListItemIcon>
                    {sidebarOpen ? (
                      <Icon fontSize="small" color="primary">
                        {m.icon}
                      </Icon>
                    ) : (
                      <Tooltip title={t(m.ttoken) as string}>
                        <Icon fontSize="small" color="primary">
                          {m.icon}
                        </Icon>
                      </Tooltip>
                    )}
                  </ListItemIcon>
                  <ListItemText primary={t(m.ttoken)} />
                </ListItem>
              );
            }
          })}
        </List>
      </Drawer>
    </aside>
  );
};

export default NavDrawer;
