import { createContext } from 'react';

//Types
import { SidebarContextType } from "@/@types/components/Map/Controls/Sidebar/SidebarContext";

const SidebarContext = createContext<SidebarContextType | null>(null);

SidebarContext.displayName = 'SidebarContext'

export default SidebarContext
