import * as React from "react";
import { Fragment } from "react";
import { FunctionComponent } from "react";

//MUI
import DialogTitle from "@mui/material/DialogTitle";

//Types
import { IDialogHeader } from "@/@types/ui/Dialog";

const DialogHeader: FunctionComponent<IDialogHeader> = (props) => {
  const { children, color } = props;

  return (
    <Fragment>
      <DialogTitle
        className="dialogTitle grabbable"
        sx={{
          color: "common.white",
          backgroundColor: color ? color : "primary.main"
        }}
      >
        {children}
      </DialogTitle>
    </Fragment>
  );
};

export default DialogHeader;
