import React, { Fragment, useEffect, useRef, useState } from "react";
import { MouseEvent, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

// Material-UI Components
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from '@mui/material/Paper';
import Popper from "@mui/material/Popper";
import Popover from "@mui/material/Popover";
import Select from "@mui/material/Select";

// Custom Components
import { dateOptions, dateSubOptions } from "@/lib/dateFilterOptions";
// import CalendarControl from "@/controls/CalendarControl";

import {
  DateFilterWrapperFn,
  IDateColumnFilterProps
} from "@/@types/ui/Table";
import { IDate } from "@/@types/common";

//Types
import { DateHeadingValue } from "@/@types/lib/dateFilterOptions";


export const dateColumnFilter: DateFilterWrapperFn = (setFilterLocalStorage) => ({column: {filterValue, setFilter, preFilteredRows, id}}) => {
  return (
    <DateColumnFilter
    	// id={id}
    	filterValue={filterValue}
    	// setFilter={setFilter}
    	onFilterChange={(value: IDate | null) => setFilterLocalStorage(id, value, setFilter)}
    />
  );
}

const getInitialValue = (filterValue: string) => {
  if (filterValue === "all") return filterValue;
  let res = "";
  Object.keys(dateSubOptions).forEach((k) => {
    if (res !== "") return;
    (dateSubOptions)[k as DateHeadingValue].forEach((subOpt) => {
      if (res !== "") return;
      if (filterValue === subOpt.value) {
        res = k;
      }
    })
  })
  return res === "" ? "custom" : res;
}

function DateColumnFilter(props: IDateColumnFilterProps) {
  const { t } = useTranslation();
  const {filterValue, onFilterChange} = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuHovered, setMenuHovered] = useState("");
  const [menuValue, setMenuValue] = useState(getInitialValue(filterValue));
  const [selectOpen, setSelectOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [customRecord, setCustomRecord] = useState<Array<IDate>>([]);

  const handleHover = (evt: MouseEvent<HTMLLIElement>, menu: string) => {
    if(menu == "custom" || menu == "all") {
      setAnchorEl(null);
    } else if(selectOpen) {
      setAnchorEl(evt.currentTarget);
      setMenuHovered(menu);
    }
  }

  const handleChange = (value: string) => {
    // if (!evt) return;
    // const { target } = evt;
    // if (!target) return;
    // const { value } = (target);
    if (!value) return;

    switch (value) {
      case "all":
        onFilterChange("");
        setSelectOpen(false);
        break;
      case "custom":
        setPopoverOpen(true);
        setSelectOpen(false);
        break;
      default:
        break;
    }
    setMenuValue(value);
  }

  // Function handles clicks on popper Menuitems
  const handleMenuItemClick = (value:string, menuHovered:string) => {
    onFilterChange(value);
    setCustomRecord([]);
    setMenuValue(menuHovered);
    setSelectOpen(false);
  }

  // Returns filterValue to the Select component through renderValue
  const getFilterValue = () => {
    if(filterValue) {
      if(filterValue instanceof Object) {
        return t("datefilter.custom")
      }
      return t("datefilter." + filterValue);
    }
    return t("common.all");
  }

  // Sets the popper anchor to null if select is not open
  useEffect(() => {
    if(!selectOpen) {
      setAnchorEl(null)
    }
  }, [selectOpen])

  // Closes the calendar while setting the filter and custom record
  const commitCustomFilter = (rec: IDate) => {
      setPopoverOpen(false);
      setSelectOpen(false);
      onFilterChange(rec);
      setCustomRecord([rec]);
  }

  // Selecting a range on the popover calendar triggers this
  const onRangeSelected = (date: IDate) => {
    // commitCustomFilter({startDate: date.startDate, endDate: date.endDate});
    commitCustomFilter(date);
  }

  // Force close popover Calendar if clicked target has no class, meaning that the calendar has not been clicked
  const closeCalendar = () => {
    setPopoverOpen(false);
  }

  return (
    <Fragment>
      <Select
        open={selectOpen}
        renderValue={getFilterValue}
        margin="dense"
        value={menuValue}
        onOpen={() => setSelectOpen(true)}
        onClose={() => setSelectOpen(false)}
        onChange={(evt, value) => handleChange(value as string)}
      >
        <MenuItem
          onMouseEnter={(evt) => handleHover(evt, "all")}
          value="all"
        >
          {t("common.all")}
        </MenuItem>
        {
          dateOptions.map(({ title, value }, i: number) =>
            <MenuItem
              key={i}
              value={value as string}
              onMouseEnter={(evt) => handleHover(evt, value as string)}
            >
              {t(title)}
            </MenuItem>
          )
        }
        {/* <MenuItem
          value="custom"
          onMouseEnter={(evt) => handleHover(evt, "custom")}
        >
          {t("datefilter.custom")}
        </MenuItem> */}
      </Select>
      <Popper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        placement="right"
        style={{ zIndex: 1400 }}
        // onResize={() => {}}
        // onResizeCapture={() => {}}
      >
        <Paper elevation={1} style={{borderBottomLeftRadius: "0px", borderTopLeftRadius: "0px"}}>
          <MenuList>
            {
              ((dateSubOptions)[menuHovered as DateHeadingValue] || []).map(({value, label}, i) =>
                <MenuItem
                  key={i}
                  value={value}
                  onClick={() => handleMenuItemClick(value, menuHovered)}
                >
                  {t(label)}
                </MenuItem>
              )
            }
          </MenuList>
        </Paper>
      </Popper>
      <Popover
        anchorReference="anchorPosition"
        anchorPosition={{
          left: 250,
          top: 250,
        }}
        open={popoverOpen}
        onClick={() => closeCalendar()}
      >
        {/* <CalendarControl
          roundRangeLimits={true}
          enableRangeSelection={true}
          records={customRecord}
          onRangeSelected={onRangeSelected}
        /> */}
      </Popover>
    </Fragment>
  )
}