import * as React from "react";
import { useContext } from "react";

//MUI
import Icon from "@mui/material/Icon";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

//Custom components
import UserContext from "@/components/UserContext/UserContext";

//Types
import { IHeaderMenuItem } from "@/@types/ui/Header";
import { UserContextType } from "@/@types/context/UserContext";

const HeaderMenuItem = React.forwardRef<HTMLLIElement, IHeaderMenuItem>((props, ref) => {
  const userContext = useContext(UserContext) as UserContextType;
  const { onClick, label, icon, needPermission } = props;

  if (needPermission && !userContext.hasAnyPermission(needPermission)) {
    return null;
  } else {
    return (
      <MenuItem onClick={onClick} ref={ref}>
        {icon ? (
          <ListItemIcon sx={{ minWidth: 32, color: "white" }}>
            <Icon>{icon}</Icon>
          </ListItemIcon>
        ) : null}
        {label ? <Typography variant="button" sx={{color: 'white'}}>{label}</Typography> : null}
      </MenuItem>
    );
  }
});

export default HeaderMenuItem;

