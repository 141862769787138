import { FunctionComponent, useContext, useEffect } from "react";
import clsx from "clsx";

import FullScreen from "ol/control/FullScreen";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlControl } from "@/lib/olHelpers";
import makeMUIControlStyle from "../makeMUIControlStyle";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IFullScreenControl } from "@/@types/components/Map/Controls/OpenLayers";

const FullScreenControl: FunctionComponent<IFullScreenControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;
  const classes = makeMUIControlStyle();

  const defaultLabelHtmlElement = document.createElement('i')
  defaultLabelHtmlElement.setAttribute('class','fas fa-expand-alt');

  const defaultLabelActiveHtmlElement = document.createElement('i')
  defaultLabelActiveHtmlElement.setAttribute('class','fas fa-compress-alt');

  const overwrittenProps = {
    ...props,
    className: clsx(classes.control, props.className)
  }

  const options = {
    className: clsx(classes.control),
    label: defaultLabelHtmlElement,
    labelActive: defaultLabelActiveHtmlElement,
    activeClassName: "ol-full-screen-true",
    inactiveClassName: "ol-full-screen-false",
    keys: undefined,
    target: undefined,
    source: undefined
  };

  const events = {
    change: undefined,
    enterfullscreen: undefined,
    error: undefined,
    leavefullscreen: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, FullScreen, overwrittenProps, options, events), [
    props.className,
    props.label,
    props.labelActive,
    props.activeClassName,
    props.inactiveClassName,
    props.tipLabel,
    props.keys,
    props.target,
    props.source,
    classes.control
  ]);

  return null;
};

export default FullScreenControl;
