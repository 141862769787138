import { IModel } from "@/@types/models/model";
import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";

const Uporaba: IModel = {
  title: "Uporaba",
  apiPath: "data/Uporaba",
  forms: {
    "default": {
      fields: ["id", "zis_uporaba_id", "kultura", "povrsina", "kc", "ko_mb", "ko_ime"]
    }
  },
  listViews: {
    "default": {
      fields: ["id"]
    }
  },
  fields: [
    coreFields.id,
    commonFields.zis_uporaba_id,
    commonFields.kultura,
    commonFields.povrsina,
    commonFields.kc,
    commonFields.ko_mb,
    commonFields.ko_ime
  ]
}

export default Uporaba;