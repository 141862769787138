import { IModel } from "@/@types/models/model";
import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";

const Zone: IModel = {
  title: "Zone",
  apiPath: "data/Zone",
  forms: {
    "default": {
      fields: ["id", "zona", "zis_uporaba_id", "kultura", "kc", "ko_mb", "ko_ime", "povrsina"]
    }
  },
  listViews: {
    "default": {
      fields: ["id"]
    }
  },
  fields: [
    coreFields.id,
    commonFields.zona,
    commonFields.zis_uporaba_id,
    commonFields.kultura,
    commonFields.kc,
    commonFields.ko_mb,
    commonFields.ko_ime,
    commonFields.povrsina
  ]
}

export default Zone;