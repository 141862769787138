import * as React from "react";
import { useTranslation } from "react-i18next";

//MUI
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

//MUI Icons
import TranslateIcon from "@mui/icons-material/Translate";

//Custom Components
import HeaderMenuButton from "@/ui/Header/HeaderMenuButton";
import HeaderMenuItem from "@/ui/Header/HeaderMenuItem";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <HeaderMenuButton text={i18n.language} startIcon={mdUp ? <TranslateIcon /> : null} showExpandIcon={mdUp} desc="language">
      <HeaderMenuItem
        label="Hrvatski"
        onClick={() => {
          i18n.changeLanguage("hr");
        }}
      />
      <HeaderMenuItem
        label="English"
        onClick={() => {
          i18n.changeLanguage("en");
        }}
      />
    </HeaderMenuButton>
  );
};

export default LanguageSelector;

