import { IModel } from "@/@types/models/model";
import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";

const Paneli: IModel = {
  title: "Paneli",
  apiPath: "paneli",
  forms: {
    "default": {
      fields: ["id", "snaga", "duzina_mm", "sirina_mm", "kc", "ko_mb", "ko_ime", "komentar"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "snaga", "duzina_mm", "sirina_mm", "povrsina", "kc", "ko_mb", "ko_ime", "komentar"],
      hidden: []
    }
  },
  fields: [
    coreFields.id,
    commonFields.kc,
    commonFields.ko_mb,
    commonFields.ko_ime,
    {
      title: "Površina",
      source: "povrsina",
      ttoken: "common.povrsina_m2",
      type: "numeric",
      readonly: true
    },
    {
      title: "snaga",
      source: "snaga",
      ttoken: "common.snaga",
      type: "numeric",
      readonly: true
    },
    {
      title: "duzina_mm",
      source: "duzina_mm",
      ttoken: "common.duzina_mm",
      type: "numeric",
      readonly: true
    },
    {
      title: "sirina_mm",
      source: "sirina_mm",
      ttoken: "common.sirina_mm",
      type: "numeric",
      readonly: true
    },
    {
      title: "komentar",
      source: "komentar",
      ttoken: "common.komentar",
      type: "text",
      readonly: true
    },
    {
      title: "wkt",
      source: "wkt",
      ttoken: "common.wkt",
      type: "wkt",
      readonly: true
    }
  ]
}

export default Paneli;