import React, { useContext} from "react";

//OL
import OlLayerGroup from "ol/layer/Group";
import OlBaseLayer from "ol/layer/Base";
import OlTileLayer from "ol/layer/Tile";
import OlImageLayer from "ol/layer/Image";
import OlCollection from "ol/Collection";
import OlTile from "ol/Tile";
import OlTileState from 'ol/TileState';

//Custom Components
import TileLayer from "@/components/Map/Layers/TileLayer";
import ImageLayer from "@/components/Map/Layers/ImageLayer";
import MapContext from "@/components/Map/MapContext";
import { flattenLayers } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";

const gs_url = process.env.REACT_APP_GEOSERVERPATH;

type GeoLayerCombinedProps = {
  id: string;
  layersCollection: OlCollection<OlBaseLayer>;
}

function GeoLayerCombined(props: GeoLayerCombinedProps) {
  const mapContext = useContext<MapContextType | null>(MapContext);
  const { id, layersCollection, ...otherProps } = props;

  const visibility = mapContext?.getLayerVisibility()
  const hiddenLayers = visibility ? Object.keys(visibility).filter(key => visibility[key] === false) : [];
  // console.log(hiddenLayers);

  const allLayers = flattenLayers(layersCollection.getArray()).filter(x => !(x instanceof OlLayerGroup));
  // console.log(allLayers);

  const visibleLayers = allLayers.filter(x => hiddenLayers.indexOf(x.get("id")) === -1);
  // console.log(visibleLayers);

  const GSlayerNames = visibleLayers.map(x => x.get("layer"));
  // console.log(GSlayerNames);

  const GSStyles = visibleLayers.map(x => x.get("styles"));

  const visible = GSlayerNames.length > 0;

  const format = visibleLayers.length > 0 ? visibleLayers[0].get('format') : "image/png8";

  const tiled = visibleLayers.length > 0 ? visibleLayers[0].get('tiled') : true;

  const zIndex = visibleLayers.length > 0 ? visibleLayers[0].get('z_index') : true;
  const extent = visibleLayers.length > 0 ? visibleLayers[0].get('extent') : true;

  // const customLoader = (tile: OlTile, src: string) => {
  //   const token = localStorage.getItem('auth_token')
  //   var xhr = new XMLHttpRequest();
  //   xhr.responseType = 'blob';
  //   xhr.addEventListener('loadend', function (evt) {
  //     var data = this.response;
  //     if (data !== undefined) {
  //       //@ts-ignore
  //       tile.getImage().src = URL.createObjectURL(data);
  //     } else {
  //       tile.setState(OlTileState.ERROR);
  //     }
  //   });
  //   xhr.addEventListener('error', function () {
  //     tile.setState(OlTileState.ERROR);
  //   });
  //   xhr.open('GET', src);
  //   if (token) {
  //     xhr.setRequestHeader("Authorization", "Bearer " + token)
  //   }
  //   xhr.send();
  // }

  // console.log('render GSCombined', id, GSlayerNames, visible);

  const isTileLayer = visibleLayers.length > 0 ? visibleLayers[0] instanceof OlTileLayer : false
  const isImageLayer = visibleLayers.length > 0 ? visibleLayers[0] instanceof OlImageLayer : false;

   return (
    isTileLayer ?
    <TileLayer
      id={id}
      title={id}
      {...otherProps}
      visible={visible}
      wms={{
        url: gs_url,
        params: {
          LAYERS: GSlayerNames.join(","),
          STYLES: GSStyles.join(","),
          FORMAT: format,
          TILED: tiled,
          VIEWPARAMS: "token:" + localStorage.getItem('auth_token')
        },
        // tileLoadFunction: customLoader
      }}
    />
    : isImageLayer ?
    <ImageLayer
      id={id}
      visible={visible}
      zIndex={zIndex}
      extent={extent}
      wms={{
        url: gs_url,
        serverType: "geoserver",
        ratio: 1.2,
        params: {
          LAYERS: GSlayerNames.join(","),
          STYLES: GSStyles.join(","),
          FORMAT: format,
          //@ts-ignore
          authkey: localStorage.getItem('auth_token')
        }
      }}
    />
    : null
  );
}

export default GeoLayerCombined;
