import { createContext } from "react"

//Types
import { SnackbarContextType } from "@/@types/ui/SnackbarContext";

const SnackbarContext = createContext<SnackbarContextType>({
  status: {show: false, message: "", variant: "", keepOpen: false},
  showNotification: () => {},
  hideNotification: () => {}
});

SnackbarContext.displayName = "SnackbarContext";

export default SnackbarContext;
