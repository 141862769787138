import * as React from "react";
import { SyntheticEvent, useContext } from "react";

//MUI Icons
import LightModeIcon from "@mui/icons-material/Brightness4";
import DarkModeIcon from "@mui/icons-material/BrightnessHigh";

//Custom Components
import ThemeContext from "@/components/ThemeContext/ThemeContext";
import HeaderIconButton from "@/ui/Header/HeaderIconButton";

//Types
import { ThemeContextType, Theme } from "@/@types/context/ThemeContext";

const ThemeSwitch = () => {
  const themeContext = useContext(ThemeContext) as ThemeContextType;

  const handleThemeChange = (evt: SyntheticEvent): void => {
    let newTheme: Theme = themeContext.theme === "light" ? "dark" : "light";

    themeContext.setTheme(newTheme);
  };

  return (
    <HeaderIconButton onClick={handleThemeChange} desc="theme">
      {themeContext.theme === "light" ? <LightModeIcon /> : <DarkModeIcon />}
    </HeaderIconButton>
  );
};

export default ThemeSwitch;

