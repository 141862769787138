import { FunctionComponent, useContext, useEffect } from "react";
import clsx from "clsx";

//Openlayers
import ScaleLine from "ol/control/ScaleLine";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlControl } from "@/lib/olHelpers";
import makeMUIControlStyle from "../makeMUIControlStyle";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IScaleLineControl } from "@/@types/components/Map/Controls/OpenLayers";

const ScaleLineControl: FunctionComponent<IScaleLineControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;
  const classes = makeMUIControlStyle();

  const options = {
    className: clsx(classes.scaleline, "ol-scale-line"),
    minWidth: undefined,
    render: undefined,
    target: undefined,
    units: undefined,
    bar: undefined,
    steps: undefined,
    text: undefined
  };

  const events = {
    change: undefined,
    "change:units": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, ScaleLine, props, options, events), [
    props.className,
    props.minWidth,
    props.render,
    props.target,
    props.units,
    props.bar,
    props.steps,
    props.text,
    classes.scaleline
  ]);

  return null;
};

export default ScaleLineControl;

