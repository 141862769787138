import * as React from "react";
import { Fragment, FunctionComponent, useContext } from "react";

//Material UI
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

//Custom components
import SnackbarContext from "./SnackbarContext";

//Types
import { SnackbarContextType } from "@/@types/ui/SnackbarContext";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarContainer = () => {
  const snackbarContext = useContext(SnackbarContext);

  const variant = snackbarContext && snackbarContext.status.variant ? snackbarContext.status.variant : "info";
  const message = snackbarContext ? snackbarContext.status.message : "";
  const keepOpen = snackbarContext ? snackbarContext.status.keepOpen : false;

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    if (snackbarContext && snackbarContext.hideNotification) {
      snackbarContext.hideNotification();
    }
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        key={"snackglobal"}
        open={snackbarContext ? snackbarContext.status.show : false}
        onClose={(evt, reason) => handleClose(evt as React.SyntheticEvent, reason)}
        autoHideDuration={keepOpen ? null : 3000}
      >
        <Alert severity={variant} onClose={handleClose} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default SnackbarContainer;
