import * as React from "react";
import { Fragment, FunctionComponent, useContext } from "react";

//Material UI
import { Backdrop, CircularProgress } from "@mui/material";

//Custom components
import LoaderContext from "./LoaderContext";

//@types
import { LoaderContextType } from "@/@types/context/LoaderContext";

const LoaderContainer = () => {
  const loaderContext = useContext(LoaderContext) as LoaderContextType

  return (
    <Fragment>
      <Backdrop
        open={Boolean(loaderContext.loading)}
        style={{
          zIndex: 99999,
          color: "#fff"
        }}
      >
        <CircularProgress disableShrink />
      </Backdrop>
    </Fragment>
  );
};

export default LoaderContainer;

