import * as React from "react";
import { FunctionComponent, SyntheticEvent, useContext } from "react";

//Material UI
import { useTheme } from "@mui/material";

//Custom components
import SidebarContext from "./SidebarContext/SidebarContext";

//Types
import { ISidebarHeading } from "@/@types/components/Map/Controls/Sidebar";
import { SidebarContextType } from "@/@types/components/Map/Controls/Sidebar/SidebarContext";

const SidebarHeading: FunctionComponent<ISidebarHeading> = (props) => {
  const sidebarContext = useContext(SidebarContext) as SidebarContextType;

  const theme = useTheme();

  const { title } = props;

  const handleCollapse = (evt: SyntheticEvent): void => {
    sidebarContext.setCollapsed(true);
  };

  return (
    <h1
      style={{
        backgroundColor: theme.palette.primary.main,
        margin: "-10px -20px 10px",
        height: 40,
        padding: "0 20px",
        paddingLeft: sidebarContext?.position === "right" ? 40 : 20,
        lineHeight: "40px",
        fontSize: "14.4pt",
        color: "#fff"
      }}
    >
      {title}
      <span className="sidebar-close" onClick={handleCollapse}>
        <i className={`fas ${sidebarContext?.position === "right" ? "fa-caret-right" : "fa-caret-left"}`} />
      </span>
    </h1>
  );
};

export default SidebarHeading;
