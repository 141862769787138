import * as React from "react";
import { ReactElement, useState } from "react";
import { useTranslation } from 'react-i18next';

import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";

//Types
import { INotificationStatus } from "@/@types/ui/SnackbarContext";

const SnackbarProvider = ({ children }: { children: ReactElement }) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState<INotificationStatus>({show: false, message: "", variant: "", keepOpen: false});

  const showNotification = (message: string, type: "success" | "info" | "warning" | "error" | string, keepOpen: boolean = false): void => {

    let msg = t("notifications.action");
    switch (message) {
      case "update":
        msg = t("notifications.update");
        break;
      case "insert":
        msg = t("notifications.insert");
        break;
      case "delete":
        msg = t("notifications.delete");
        break;
      case "copy":
        msg = t("notifications.copy");
        break;
      default:
        if (message && message.length > 0) {
          msg = t(message);
        }
        break;
    }

    let newVariant = status.variant;
    switch (type) {
      case "success":
      case "info":
      case "error":
      case "warning":
        newVariant = type;
    }

    setStatus({show: true, message: msg, variant: newVariant, keepOpen: keepOpen})
  }

  const hideNotification = () => {
    setStatus({show: false, message: "", variant: "", keepOpen: false})
  }

  const providerValue = {
    status,
    showNotification,
    hideNotification
  }

  return (
    <SnackbarContext.Provider value={providerValue}>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;

