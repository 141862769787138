import { TFunction } from "i18next";
import { PickerItem } from "../@types/controls/controls";

export function fieldFormat(obj: PickerItem, format: string, t: TFunction) {
  let keys = Object.keys(obj);

  if (format) {
    let label = format;
    if (keys.length > 0) {
      keys.forEach((k) => {
        const key = k as keyof PickerItem;
        let re = new RegExp('{' + k + '}');
        label = label.replace(re, obj[key] != null ? obj[key] as string: '');
      })
      return t(label);
    } else {
      return null;
    }
  } else {
    return keys.map(x => {
      const xkey = x as keyof PickerItem;
      return t(obj[xkey] as string)
    }).join(', ');
  }
}