import * as React from "react";
import { FunctionComponent, useContext } from "react";

//Custom components
import UserContext from "@/components/UserContext/UserContext";
import SidebarContext from "./SidebarContext/SidebarContext";

//Types
import { Permission } from "@/@types/common";
import { SidebarContextType } from "@/@types/components/Map/Controls/Sidebar/SidebarContext";
import { UserContextType } from "@/@types/context/UserContext";

type SidebarPaneProps = {
  id: string,
  needPermission?: [Permission]
}

const SidebarPane: FunctionComponent<SidebarPaneProps> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;
  const sidebarContext = useContext(SidebarContext) as SidebarContextType;

  const { id, needPermission } = props;

  const isActive = sidebarContext.activePane === id;

  return needPermission && !userContext.hasAnyPermission(needPermission) ? null :
   isActive ? (
    <div className="sidebar-pane active" id={id}>
      {props.children}
    </div>
  ) : null;
};

export default SidebarPane;
