import { FunctionComponent, useContext, useEffect } from "react";
import clsx from "clsx";

//OpenLayers
import ZoomToExtent from "ol/control/ZoomToExtent";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";
import BarControlContext from "../OpenLayersExt/Bar/BarControlContext";
import makeMUIControlStyle from "../makeMUIControlStyle";

//Types
import { IZoomToExtentControl } from "@/@types/components/Map/Controls/OpenLayers";
import { MapContextType } from "@/@types/context/MapContext";
import { BarControlContextType } from "@/@types/components/Map/Controls/OpenLayersExt/Bar";

const ZoomToExtentControl: FunctionComponent<IZoomToExtentControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;
  const parentBarContext = useContext(BarControlContext) as BarControlContextType;
  const classes = makeMUIControlStyle();

  const { labelClass } = props;

  const defaultHtmlElement = document.createElement('i')
  defaultHtmlElement.setAttribute('class', labelClass ? labelClass : 'fas fa-expand');

  const overwrittenProps = {
    ...props,
    className: clsx(props.className, classes.control)
  }

  const options = {
    className: clsx(classes.control, "ol-zoom-extent"),
    target: undefined,
    label: defaultHtmlElement,
    tipLabel: undefined,
    extent: undefined
  };

  const events = {
    change: undefined,
    propertychange: undefined
  };


  useEffect(() => {
    let allOptions = Object.assign(options, overwrittenProps);
    let definedOptions = getDefinedOptions(allOptions);

    let control = new ZoomToExtent(definedOptions);

    if(props.id) {
      control.set("id", props.id)
    }

    if (parentBarContext && parentBarContext.exists && parentBarContext.childControls) {
      parentBarContext.childControls.push(control);

    } else if (context.map) {
      const mapControl = findControl(context.map, props.id, ZoomToExtent);
      if (mapControl) {
        context.map.removeControl(mapControl);
      }
      context.map.addControl(control);
    } else {
      context.initOptions.controls.push(control);
    }

    let olEvents = getEvents(events, props);
    for (let eventName in olEvents) {
      //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
      control.on(eventName, olEvents[eventName]);
    }

    return () => {
      if (context.map) {
        const mapControl = findControl(context.map, props.id, ZoomToExtent);
        if (mapControl) {
          context.map.removeControl(mapControl);
        }
      }
    }
  }, [
    props.className,
    props.target,
    props.label,
    props.tipLabel,
    props.extent,
    classes.control
  ])

  return null;
};

export default ZoomToExtentControl;

