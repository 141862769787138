import moment from "moment";

// @Piero
// resolve any any

export {
  filterCustom
, filterDate
, filterElementProperty
, filterMulti
}

import { FilteringFn } from "@/@types/ui/Table";

const filterCustom: FilteringFn<any, any> = (toMatch, currValue) => true;

const filterDate: FilteringFn<Date, string | string[] | Date> = (toMatch, currValue) => {
  const compare_date = moment()
  const seven_days = currValue === "last_7_days" ? moment().subtract(7, "days") : moment().add(7, "days");
  const thirty_days = currValue === "last_30_days" ? moment().subtract(30, "days") : moment().add(30, "days");
  const week_start = moment().startOf("week");
  const week_end = moment().endOf("week");

  if(Array.isArray(currValue)) {
    const start = moment(currValue[0]);
    const end = moment(currValue[1]);
    return moment(toMatch).isBetween(start, end, "days", "[]");
  }

  switch(currValue) {
    case "today": 
    case "yesterday": 
    case "tomorrow":
      if(currValue === "tomorrow") {
        compare_date.add(1, "day");
      } else if(currValue === "yesterday") {
        compare_date.subtract(1, "day");
      }
      return compare_date.isSame(toMatch, "day");
    case "this_week": 
      return moment(toMatch).isSame(compare_date, "week");
    case "next_week": 
      compare_date.add(1, "weeks");
      return moment(toMatch).isSame(compare_date, "week");
    case "last_week": 
      compare_date.subtract(1, "weeks");
      return moment(toMatch).isSame(compare_date, "week");
    case "next_2_weeks": 
    return moment(toMatch).isBetween(moment(week_start).add(1, "weeks"), moment(week_end).add(2, "weeks"), "days", "[]");
    case "last_2_weeks": 
      return moment(toMatch).isBetween(moment(week_start).subtract(2, "weeks"), moment(week_end).subtract(1, "weeks"), "days", "[]");
    case "next_7_days":
      return moment(toMatch).isBetween(compare_date, seven_days, "days", "[]");
    case "last_7_days":
      return moment(toMatch).isBetween(seven_days, compare_date, "days", "[]");
    case "this_month":
    case "next_month":
    case "last_month":
      if(currValue === "next_month") {
        compare_date.add(1, "months");
      } else if(currValue === "last_month") {
        compare_date.subtract(1, "months");
      }
      return moment(toMatch).isSame(compare_date, "month");
    case "next_30_days": 
      return moment(toMatch).isBetween(compare_date, thirty_days, "days", "[]");
    case "last_30_days":
      return moment(toMatch).isBetween(thirty_days, compare_date, "days", "[]");
    case "this_year":
    case "last_year":
    case "next_year":
      if(currValue === "last_year") {
        compare_date.subtract(1, "years");
      } else if(currValue === "next_year") {
        compare_date.add(1, "years");
      }
      return moment(toMatch).isSame(compare_date, "year");
    default: 
      return true
  }
  return true;
}

const filterElementProperty: FilteringFn<number, string> = (toMatch, currValue) => {
  if(toMatch && currValue){
    return toMatch === parseInt(currValue);
  } else {
    return false
  }
}

const filterMulti: FilteringFn<number[], number[]> = (toMatch, currValue) => {
  if (toMatch && currValue) {
    const _res = currValue.map((val: number) => toMatch.indexOf(val) !== -1)
    return _res.every((val: boolean) => val);
  }
  return false;
}
