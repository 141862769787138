import * as React from "react";
import { useContext, useRef, useEffect, FunctionComponent } from "react";

//Custom components
import MapContext from "@/components/Map/MapContext";
import Sidebar from "./ol5-sidebar";
import "./ol3-sidebar.css";
import SidebarContext from "./SidebarContext/SidebarContext";
import { findControl, getDefinedOptions, getEvents } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { ISidebarRoot } from "@/@types/components/Map/Controls/Sidebar";
import { SidebarContextType } from "@/@types/components/Map/Controls/Sidebar/SidebarContext";

const SidebarRoot: FunctionComponent<ISidebarRoot> = (props) => {
  const mapContext = useContext(MapContext) as MapContextType;
  const sideBarContext = useContext(SidebarContext) as SidebarContextType;
  const sidebarRef = useRef(null);

  useEffect(() => {
    // console.log("useEffect Sidebar", props);
    // console.log(sidebarRef);
    if (sidebarRef.current) {
      const options = {
        element: sidebarRef.current,
        position: "left"
      };

      let allOptions = Object.assign(options, props);
      let definedOptions = getDefinedOptions(allOptions);

      const events = {};

      let control = new Sidebar(definedOptions);

      if (props.id) {
        control.set("id", props.id);
      }

      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, Sidebar);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
        mapContext.map.addControl(control);
      } else {
        mapContext.initOptions.controls.push(control);
      }

      let olEvents = getEvents(events, props);
      for (let eventName in olEvents) {
        //@ts-ignore TODO:  Argument of type 'string' is not assignable to parameter of type '("error" | "change" | "propertychange")[]'
        control.on(eventName, olEvents[eventName]);
      }
    }

    return () => {
      if (mapContext.map) {
        const mapControl = findControl(mapContext.map, props.id, Sidebar);
        if (mapControl) {
          mapContext.map.removeControl(mapControl);
        }
      }
    };
  }, []);

  let classNames = ["sidebar"];
  classNames.push("sidebar-" + (props.position ? props.position : "left"));
  if (sideBarContext.collapsed) {
    classNames.push("collapsed");
  }
  const joinedClassNames = classNames.join(" ");

  return (
    <div id="sidebar" className={joinedClassNames} ref={sidebarRef}>
      {props.children}
    </div>
  );
};

export default SidebarRoot;
