import * as React from "react";

//types
import { ITextControlProps } from "@/@types/controls/controls";

//Custom Controls
import BaseInputControl from "./BaseInputControl";

function TextControl(props: ITextControlProps) {
  return <BaseInputControl {...props} />;
}
export default TextControl;
