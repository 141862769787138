import React, { useContext, SyntheticEvent, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';


//Custom Components
import useFormController from "@/components/useFormController";
import DraggableDialog from "@/ui/Dialog/DraggableDialog";
import DialogHeader from "@/ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogActionButtonCancel from "@/ui/Dialog/ActionButtons/DialogActionButtonCancel";
import DialogContext from "@/ui/DialogContext/DialogContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import GridContainer from "@/ui/Grid/GridContainer";
import TextControl from "@/controls/TextControl";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
//Types
import { ClosingDetails, FormControllerProps } from "@/@types/components/formController";
import { IDialogProps } from "@/@types/ui/DialogContext";
import { GridItem } from "../Grid";
import DialogActionButton from "./DialogActionButton";

const ExportMapDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();

  const [format, setFormat] = useState("pdf")
  const [name, setFileName] = useState("")

  const { handleDownloadMap } = props
  const close = () => {

    dialogContext.hideDialog();
  };

  return (
    <DraggableDialog open={true} maxWidth={"sm"} onClose={close}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogToolbarHeading>{t("titles.map_print")}</DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={close} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>

            <GridItem xs={12}>
              <GridContainer flex={"row"} justifyContent={"center"}>
                <GridItem>
                  <Stack direction="row" spacing={2}>
                    <Chip label="PDF" color="primary" variant={format === "pdf" ? "filled" : "outlined"} onClick={() => setFormat("pdf")}/>
                    <Chip label="PNG" color="primary" variant={format === "png" ? "filled" : "outlined"} onClick={() => setFormat("png")} />
                  </Stack>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12}>

              <TextControl
                //@ts-ignore
                field={""}
                value={name}
                onChange={(e: any) => { setFileName(e) }}
                placeholder={t("common.filename")}
              />

            </GridItem>
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        <DialogActionButtonCancel variant="outlined" onClick={close} />
        <DialogActionButton startIcon={<FileDownloadIcon />} ariaLabel="Download" ariaControls="" variant="contained" onClick={() => handleDownloadMap(format, name)}> {t("buttons.download")}</DialogActionButton>
      </DialogActions>
    </DraggableDialog>
  );
}

export default ExportMapDialog;
