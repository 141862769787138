import * as React from "react";
import { FunctionComponent, Fragment, useContext } from "react";

//MUI
import Button from "@mui/material/Button";

//Custom Components
import UserContext from "@/components/UserContext/UserContext";

//Types
import { IDialogActionButton } from "@/@types/ui/Dialog";
import { UserContextType } from "@/@types/context/UserContext";

const DialogActionButton: FunctionComponent<IDialogActionButton> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;

  const { needPermission, startIcon, onClick, variant, disabled, ariaLabel, ariaControls } = props;

  return (
    <Fragment>
      {needPermission && !userContext.hasAnyPermission(needPermission) ? null : (
        <Button
          color="primary"
          size="medium"
          startIcon={startIcon}
          onClick={onClick}
          variant={variant}
          aria-label={ariaLabel}
          aria-controls={ariaControls}
          disabled={disabled}
          sx={{
            ml: 10,
            mr: 0
          }}
        >
          {props.children}
        </Button>
      )}
    </Fragment>
  );
};

export default DialogActionButton;
