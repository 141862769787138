import { AuthorizationHeader} from "../../@types/lib/api/api";

export function authHeader(): AuthorizationHeader {
  //return authorization header with basic auth credentials
  let auth_token: string | null = localStorage.getItem('auth_token');

  if (auth_token !== null) {
      return { 'Authorization' : 'Bearer ' + auth_token };
  } else {
      return {};
  }
}

