import React, { useContext, FunctionComponent, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";

//Custom Components
import GridContainer from "@/ui/Grid/GridContainer";
import FormContent from "@/components/FormContent";
import useAuthFormController from "@/components/useAuthFormController";

import DraggableDialog from "@/ui/Dialog/DraggableDialog";
import DialogHeader from "@/ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogActionButtonConfirm from "@/ui/Dialog/ActionButtons/DialogActionButtonConfirm";
import DialogActionButtonCancel from "@/ui/Dialog/ActionButtons/DialogActionButtonCancel";
import DialogActionButtonClose from "@/ui/Dialog/ActionButtons/DialogActionButtonClose";

import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import DialogContext from "@/ui/DialogContext/DialogContext";

//Types
import { ClosingDetails, FormControllerProps } from "@/@types/components/formController";
import { IDialogProps } from "@/@types/ui/DialogContext";

const PasswordChangeDialog: FunctionComponent<IDialogProps> = (props) => {
  const snackbarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);

  const { t } = useTranslation();
  const { form } = props;
  const { record, validation, fields, onFieldChange, doChangePassword } = useAuthFormController(
    props as FormControllerProps
  );

  const { onClose } = props;

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleChange = (evt: SyntheticEvent) => {
    if (doChangePassword) {
      doChangePassword().then((result) => {
        if (result.success) {
          close({ success: true });
        } else if (result.error) {
          snackbarContext.showNotification(result.error, "error");
        } else if (result.validationPass === false) {
          console.log(result.validation);
        }
      });
    }
  };

  const handleClose = (evt: SyntheticEvent) => {
    close({ success: false });
  };

  const close = (result: any) => {
    if (onClose) {
      onClose(result);
    }

    dialogContext.hideDialog();
  };

  return (
    <DraggableDialog open={true} maxWidth={"xs"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogToolbarHeading>{t("titles.password_change")}</DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer>
            <FormContent
              fieldNames={["password", "password_new", "password_new_confirm"]}
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={handleFieldChange}
              mode="insert"
              columns={1}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions>
        {form === "view" ? (
          <DialogActionButtonClose variant="contained" onClick={handleClose} />
        ) : (
          <>
            <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
            <DialogActionButtonConfirm variant="contained" onClick={handleChange} />
          </>
        )}
      </DialogActions>
    </DraggableDialog>
  );
};

export default PasswordChangeDialog;
