import React from "react";

//MUI
import IconButton, { IconButtonProps } from "@mui/material/IconButton";

//MUI Icons
import CloseIcon from "@mui/icons-material/Close";

function MapCardHeaderButtonClose(props: IconButtonProps) {
  return (
    <IconButton color="inherit" aria-label="close" aria-controls="close" {...props}>
      <CloseIcon />
    </IconButton>
  );
}

export default MapCardHeaderButtonClose;
