import { IHeaders } from '../../@types/lib/api/api';
import { authHeader } from './authHeader';

export function standardHeadersWithAuth(): IHeaders {
  let headers: IHeaders = {};

  const authorizationHeader = authHeader();
  if (authorizationHeader) {
    headers = Object.assign(headers, authorizationHeader);
  }
  headers['Content-Type'] = 'application/json';
  headers['Accept'] = 'application/json';

  return headers;
}