import React from "react";
import { useTranslation } from "react-i18next";

// Material-UI Components
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import {
  SelectFilterWrapperFn,
  PickerValue
} from "@/@types/ui/Table";
import { Row } from "react-table"


export const SelectColumnFilter: SelectFilterWrapperFn =
(setFilterLocalStorage, subModelsWithData, fieldItems) => ({column: {filterValue, setFilter, preFilteredRows, id}}) => {
  const { t } = useTranslation();

  // TODO:
  // add subModels with data as a recieved prop in the first curry
  // get their text values instead of ids to show as options (MenuItem)
  const options = React.useMemo(() => {
    const opts = new Set();
    if(fieldItems) {
      fieldItems.labels.filter((label, l) => {
        const found_value = preFilteredRows.find((pfr: Row) => pfr.values[id] === fieldItems.values[l]);
        if(found_value !== undefined) {
          if(fieldItems.icons) {
            opts.add({
              color: "primary",
              icon: fieldItems.icons[l],
              value: fieldItems.values[l],
              label: label
            })
          } else {
            opts.add({
              value: fieldItems.values[l],
              label: label
            })
          }
        }
      })
      return Array.from(opts.values());
    }
    return [];
  }, [id, preFilteredRows]);

  // this doesnt really work cuz no submodels
  // const renderOpts = options.map(optId => {
  //   return {
  //     value: optId,
  //     label: subModelsWithData && subModelsWithData[id] ?
  //       t(subModelsWithData[id].find(r => r.value === optId))
  //       : optId
  //   }
  // })

  return (
    <Select
      value={filterValue ? String(filterValue.value) : undefined}
      onChange={(e, value) => {
        setFilterLocalStorage(id, value || undefined, setFilter);
      }}
      MenuProps={{
        variant: "menu"
      }}
      variant="outlined"
      defaultValue=""
      margin="dense"
      displayEmpty
    >
      <MenuItem value="">{t("common.all")}</MenuItem>
      {
        (options as Array<PickerValue>).map((option, i) => (
          <MenuItem key={i} value={option.value}>
            { option.icon ? <Icon fontSize="small" style={{color: option.color}}>{option.icon}</Icon> : null }
            {t(option.label)}
          </MenuItem>
        ))
      }
    </Select>
  );
}
