import * as React from "react";
import { FunctionComponent } from "react";

//MUI
import DialogContent from "@mui/material/DialogContent";

//Types
import { IDialogBody } from "@/@types/ui/Dialog";

const DialogBody: FunctionComponent<IDialogBody> = (props) => {
  return <DialogContent sx={{ pt: 0 }}>{props.children}</DialogContent>;
};

export default DialogBody;
