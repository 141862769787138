import * as React from "react";
import { Fragment, FunctionComponent, useContext } from "react";

//Custom components
import MapContext from "@/components/Map/MapContext";

//Types
import { MapContextType } from "@/@types/context/MapContext";

const Layers: FunctionComponent<any> = (props) => {

  const mapContext = useContext(MapContext) as MapContextType;

  return (
    <Fragment>{ mapContext?.map ? props.children : null}</Fragment>
  )
};

export default Layers;

