import React from 'react';

export default function LoadingMessage() {
  return (
    <div className="splash-screen">
      {
        Math.floor(Math.random() * 1000) < 5
        ?
        "Vlatko, napusti internet!"
        : null
      }
      <div className="loading-dot">.</div>
    </div>
  );
}