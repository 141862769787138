import { createContext } from 'react';

//Types
import { DialogContextType } from "@/@types/ui/DialogContext";

const DialogContext = createContext<DialogContextType>({
  component1: null,
  component2: null,
  props1: {},
  props2: {},
  showDialog: () => {},
  hideDialog: () => {}
});
DialogContext.displayName = 'DialogContext';

export default DialogContext;