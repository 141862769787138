import React, { useContext, MouseEvent } from "react";

//MUI
import Button from "@mui/material/Button";

//Custom Components
import UserContext from "@/components/UserContext/UserContext";

//Types
import { ITableHeaderButtonProps } from "@/@types/ui/Table";
import { Fab, Tooltip, useMediaQuery, useTheme } from "@mui/material";

const TableHeaderButton: React.FC<ITableHeaderButtonProps> = (props) => {
  const userContext = useContext(UserContext);
  const { onClick, needPermission, smallButtons, ...otherProps } = props;

  const handleClick = function (evt: MouseEvent<HTMLButtonElement>) {
    onClick(evt);
  };

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down(1050));

  return needPermission && userContext !== null && !userContext.hasAnyPermission(needPermission) ? null : (
    (mdDown || smallButtons === true) ? 
    (
    <Tooltip title={`${props.children}`}>
      <Fab
        color="primary"
        size="small"
        onClick={handleClick}
        sx={{
          minHeight: 32,
          minWidth: 40,
          ml: 2,
          mr: 2
        }}
        >      
        {otherProps.startIcon}
      </Fab>
    </Tooltip>
    ) :
    <Button
      color="primary"
      size="small"
      {...otherProps}
      onClick={handleClick}
      sx={{
        minHeight: 32,
        ml: 1,
        mr: 0
      }}
    >
      {props.children}
    </Button>
  );
};

export default TableHeaderButton;
