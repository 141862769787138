import { FunctionComponent, useContext, useEffect } from "react";

//OpenLayers
import { KeyboardZoom } from "ol/interaction";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlInteraction } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IKeyboardZoomInteraction } from "@/@types/components/Map/Interactions/OpenLayers";

const KeyboardZoomInteraction: FunctionComponent<IKeyboardZoomInteraction> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    duration: undefined,
    condition: undefined,
    delta: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, KeyboardZoom, props, options, events),
    []
  );

  return null;
};

export default KeyboardZoomInteraction;

