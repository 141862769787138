import React from "react";

//MUI
import Slider from "@mui/material/Slider";

function OpacitySlider(props: any) {
  const { checked, handleChange, opacity } = props

    return (
        <Slider
          size="medium"
          aria-label="Opacity"
          value={opacity}
          valueLabelDisplay="auto"
          min={0}
          max={100}
          disabled={ checked ? false : true }
          onChange={handleChange}
          sx={{
            width: 100,
            mr: 3
          }}
        />
    )
}

export default OpacitySlider