import * as React from "react";
import { Fragment, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

//Types
import { IHeaderButton } from "@/@types/ui/Header";

const HeaderButton: FunctionComponent<IHeaderButton> = (props) => {
  const {t} = useTranslation();
  const { desc, onClick, startIcon, endIcon, ...rest } = props;

  const title: string = desc ? t(`tooltips.header.${desc}`) : "";

  const style = {
    marginRight: 0,
    color: 'common.white',
    "&:hover": {
      bgcolor: 'primary.dark'
    }
  }

  return (
    <Fragment>
      {desc ? (
        <Tooltip disableFocusListener placement="bottom" arrow title={title}>
          <Button
            color="inherit"
            size="small"
            onClick={onClick}
            startIcon={startIcon}
            endIcon={endIcon}
            {...rest}
            sx={style}
          >
            {props.children}
          </Button>
        </Tooltip>
      ) : (
        <Button
          color="inherit"
          size="small"
          onClick={onClick}
          startIcon={startIcon}
          endIcon={endIcon}
          {...rest}
          sx={style}
        >
          {props.children}
        </Button>
      )}
    </Fragment>
  );
};

export default HeaderButton;

