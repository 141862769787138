import * as React from "react";
import { FunctionComponent } from "react";

//MUI Icons
import CloseIcon from "@mui/icons-material/Close";

//Custom Components
import DialogToolbarButton from "@/ui/Dialog/DialogToolbarButton";

//Types
import { IDialogToolbarButtonDetail } from "@/@types/ui/Dialog";

const DialogToolbarButtonClose: FunctionComponent<IDialogToolbarButtonDetail> = (props) => {
  const { onClick } = props;

  return (
    <DialogToolbarButton ariaLabel="close" ariaControls="close" onClick={onClick}>
      <CloseIcon />
    </DialogToolbarButton>
  );
};

export default DialogToolbarButtonClose;
