import React, { useEffect } from "react";
import { createContext, FunctionComponent, useState } from "react";
import NavDrawerContext from "./NavDrawerContext";


const NavDrawerProvider: FunctionComponent<{}> = (props) => {

  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const defaultOpen = getSidebarSetting();
    setSidebarOpen(defaultOpen);
  }, [])

  const toggleSidebar = (): void => {
    localStorage.setItem("sidebar", String(!sidebarOpen));
    setSidebarOpen((prevState) => !prevState);
  };

  const getSidebarSetting = () => {
    let sidebarSetting = localStorage.getItem("sidebar");
    if (sidebarSetting === undefined || sidebarSetting === null) {
      return false;
    } else if (sidebarSetting === "false") {
      return false;
    } else return sidebarSetting === "true";
  };

  const providerValue = {
    sidebarOpen,
    toggleSidebar
  }

  return (
    <NavDrawerContext.Provider value={providerValue}>
      {props.children}
    </NavDrawerContext.Provider>
  );
}

export default NavDrawerProvider;