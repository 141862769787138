import { FunctionComponent, useContext, useEffect } from "react";
import clsx from "clsx";

//OpenLayers
import Rotate from "ol/control/Rotate";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlControl } from "@/lib/olHelpers";
import makeMUIControlStyle from "../makeMUIControlStyle";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IRotateControl } from "@/@types/components/Map/Controls/OpenLayers";

const RotateControl: FunctionComponent<IRotateControl> = (props) => {
  const context = useContext(MapContext) as MapContextType;
  const classes = makeMUIControlStyle();

  const options = {
    className: clsx(classes.control, "ol-rotate"),
    label: undefined,
    tipLabel: undefined,
    duration: undefined,
    autoHide: undefined,
    render: undefined,
    resetNorth: undefined,
    target: undefined
  };

  const events = {
    change: undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(() => registerOlControl(context, Rotate, props, options, events), [
    props.className,
    props.label,
    props.tipLabel,
    props.duration,
    props.autoHide,
    props.render,
    props.resetNorth,
    props.target,
    classes.control
  ]);

  return null;
};

export default RotateControl;

