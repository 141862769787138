import React from 'react';
import { makeStyles } from '@mui/styles';

//Types
import { Theme } from "@mui/material";

// Control Style
const makeMUIControlStyle = makeStyles((theme: Theme) => ({
  control: {
    "& > button": {
      border: 0,
      backgroundColor: theme.palette.primary.dark
    },
    "& > button:hover": {
      border: 0,
      backgroundColor: theme.palette.primary.main
    },
    "& > button:focus": {
      border: 0,
      backgroundColor: theme.palette.primary.main
    }
  },
  scaleline: {
    backgroundColor: theme.palette.primary.dark
  }
}));

export default makeMUIControlStyle;