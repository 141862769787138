// These are some common fields that are present in multiple tables in DB
// They can be added to UI models...

import { IFieldText, IFieldMail, IFieldNumberWhole, IFieldNumeric } from "@/@types/models/model";

const oib: IFieldText = {
  title: "OIB",
  source: "oib",
  ttoken: "common.oib",
  type: "text",
  validation: {
    required: true,
    minLength: 11,
    maxLength: 11,
    regex: "oib"
  },
  export: {
    width: 20
  }
};

const vat: IFieldText = {
  title: "VAT",
  source: "vat",
  ttoken: "common.vat",
  type: "text",
  validation: {
    required: true,
    maxLength: 50
  },
  export: {
    width: 20
  }
};

const email: IFieldMail = {
  title: "E-mail",
  source: "email",
  ttoken: "common.email",
  type: "mail",
  validation: {
    required: true,
    maxLength: 250,
    regex: "email"
  },
  export: {
    width: 30
  }
};

const tel: IFieldText = {
  title: "Telephone",
  source: "tel",
  ttoken: "common.tel",
  type: "text",
  //placeholder: "+385xxxxxxxxx",
  validation: {
    required: false,
    //regex: "tel"
    maxLength: 20
  },
  export: {
    width: 25
  }
};

const mobile: IFieldText = {
  title: "Mobile phone",
  source: "mobile",
  ttoken: "common.mobile",
  type: "text",
  validation: {
    required: false,
    maxLength: 20
  },
  export: {
    width: 25
  }
};

const wkt: IFieldText = {
  title: "WKT",
  source: "wkt",
  ttoken: "common.wkt",
  type: "text",
  readonly: true
}

const proj: IFieldText = {
  title: "EPSG Proj",
  source: "proj",
  ttoken: "common.proj",
  type: "text",
  readonly: true
}

const kc: IFieldText = {
  title: "Katastarska cestica",
  source: "kc",
  ttoken: "dkp.kc_broj",
  type: "text",
  readonly: true
}

const ko_mb: IFieldText = {
  title: "Katastaska općina - Matični broj",
  source: "ko_mb",
  ttoken: "dkp.ko_mb",
  type: "text",
  readonly: true
}

const ko_ime: IFieldText = {
  title: "Katastaska općina - ime",
  source: "ko_ime",
  ttoken: "dkp.ko_ime",
  type: "text",
  readonly: true
}

const duzina: IFieldNumberWhole = {
  title: "Dužina",
  source: "duzina",
  ttoken: "common.duzina",
  type: "wholenum",
  readonly: true
}

const povrsina: IFieldNumeric = {
  title: "Površina",
  source: "povrsina",
  ttoken: "common.povrsina",
  type: "numeric",
  readonly: true
}

const opis: IFieldText = {
  title: "Opis",
  source: "opis",
  ttoken: "common.opis",
  type: "text",
  readonly: true
}

const zis_uporaba_id: IFieldNumberWhole = {
  title: "zis_uporaba_id",
  source: "zis_uporaba_id",
  ttoken: "common.zis_uporaba_id",
  type: "wholenum",
  readonly: true
}

const kultura: IFieldNumberWhole = {
  title: "kultura",
  source: "kultura",
  ttoken: "common.kultura",
  type: "wholenum",
  readonly: true
}

const zona: IFieldNumberWhole = {
  title: "Zona",
  source: "zona",
  ttoken: "common.zona",
  type: "wholenum",
  readonly: true
}

export const commonFields = {
  oib,
  vat,
  email,
  tel,
  mobile,
  wkt,
  proj,
  kc,
  ko_mb,
  ko_ime,
  duzina,
  povrsina,
  opis,
  zis_uporaba_id,
  kultura,
  zona
};
