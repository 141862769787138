import React, { useContext } from "react";
import { FunctionComponent, useState } from "react";
import { position } from "ol-ext/control/control";

//Custom components
import SidebarContext from "./SidebarContext";
import MapContext from "@/components/Map/MapContext";

const SidebarProvider: FunctionComponent<any> = (props) => {

  const mapContext = useContext(MapContext);

  //Configure here default settings for Sidebar: collapsed, default pane
  const [position, setPosition] = useState<position>(props.position !== undefined ? props.position : "left");
  const [activePane, setPane] = useState<string | null>(props.initialOpenId !== undefined ? props.initialOpenId : null);
  const [collapsed, setCollapse] = useState<boolean>(props.initialOpenId !== undefined ? false: true);

  const { onTabChange } = props;

  const setActivePane = (id: string): void => {
    if(onTabChange) {
      onTabChange(id);
    }
    setPane((prevState) => prevState !== id ? id : null);
    const isCollapsed = activePane === id;
    setCollapse(isCollapsed);
    mapContext?.setSidebarCollapsed(isCollapsed);
  }

  const setCollapsed = (isCollapsed: boolean): void => {
    if (isCollapsed) {
      if(onTabChange) {
        onTabChange();
      }
      setTimeout(() => setPane(null), 380)
      setCollapse(true);
      mapContext?.setSidebarCollapsed(true);
    } else {
      setCollapse(true);
      mapContext?.setSidebarCollapsed(true);
    }
  }

  const providerValue = {
    activePane,
    collapsed,
    setActivePane,
    setCollapsed,
    position
  };

  return (
    <SidebarContext.Provider value={providerValue}>
      {props.children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;

