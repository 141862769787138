import { IHeaders } from "../../@types/lib/api/api";
import { authHeader } from "./authHeader";

export function downloadHeadersWithAuth(): IHeaders {
  let headers: IHeaders = {};

  const authorizationHeader = authHeader();
  if (authorizationHeader) {
    headers = Object.assign(headers, authorizationHeader);
  }

  //headers['Content-Type'] = 'application/octet-stream';
  headers['Content-Disposition'] = 'attachment';

  return headers;
}