import { IModel } from "@/@types/models/model";
import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";

const Trafostanice: IModel = {
  title: "Trafostanice",
  apiPath: "data/Trafostanice",
  forms: {
    "default": {
      fields: ["id", "zona", "kc", "ko_mb", "ko_ime"]
    }
  },
  listViews: {
    "default": {
      fields: ["id"]
    }
  },
  fields: [
    coreFields.id,
    commonFields.zona,
    commonFields.kc,
    commonFields.ko_mb,
    commonFields.ko_ime
  ]
}

export default Trafostanice;