import React from "react";

//MUI
import { useTheme } from "@mui/material";
import CardHeader, { CardHeaderProps } from "@mui/material/CardHeader";
import grey from "@mui/material/colors/grey";

function MapCardHeader(props: CardHeaderProps) {

  const theme = useTheme();
  return (
    <CardHeader
      {...props}
      style={{
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(1)
      }}
      sx={{
        ".MuiCardHeader-root": {
          padding: theme.spacing(1),
        },
        ".MuiCardHeader-title": {
          color: "common.white",
        },
        ".MuiCardHeader-subheader": {
          color: grey[200]
        },
        ".MuiCardHeader-action": {
          color: "common-white"
        }
      }}
    />
  );
}

export default MapCardHeader;
