import { createContext } from 'react';

//Types
import { LoaderContextType } from "@/@types/context/LoaderContext";

const LoaderContext = createContext<LoaderContextType>({
  loading: 0,
  toggleLoading: () => {}
});

LoaderContext.displayName = 'LoaderContext';
export default LoaderContext;
