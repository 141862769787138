import React from "react";

// Material-UI Components
import TextField from "@mui/material/TextField";

import {
  TextFilterWrapperFn
} from "@/@types/ui/Table";

export const TextColumnFilter: TextFilterWrapperFn = 
  (setFilterLocalStorage) => ({column: {filterValue, setFilter, preFilteredRows, id}}) => {
    return (
      <TextField
        size="small"
        value={filterValue}
        onChange={(e) => {
          setFilterLocalStorage(id, e.target.value || undefined, setFilter);
        }}
      />
    );
  }
