import * as React from "react";
import { useState, useContext, useEffect, FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { styled, useTheme } from '@mui/material/styles';

//Custom Components
import NavDrawer from "@/ui/NavDrawer/NavDrawer";
import menuRoutes from "@/routes/menu";
import UserContext from "@/components/UserContext/UserContext";
import NavDrawerContext, { NavDrawerContextType } from "@/components/NavDrawerContext/NavDrawerContext";
import NavDrawerProvider from "@/components/NavDrawerContext/NavDrawerProvider";
import Loader from "@/ui/Loader/Loader";
import AppHeader from "@/ui/Header/AppHeader";

//Types
import { IAppPage } from "@/@types/ui/AppPage";
import { UserContextType } from "@/@types/context/UserContext";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0
  }),
}));

const AppPage: FunctionComponent<IAppPage> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;
  const navDrawerContext = useContext(NavDrawerContext) as NavDrawerContextType;

  const { needPermission = [], menu, modul } = props;

  const navigate = useNavigate();

  const forbidden: boolean = needPermission.length > 0 && !userContext.hasAnyPermission(needPermission);

  useEffect(() => {
    if (userContext && userContext.loaded && forbidden) {
      console.warn("Tried to access forbidden page, Redirecting...");
      navigate("/");
    }
  }, [userContext.loaded, forbidden]);

  return userContext && userContext.loaded && !forbidden ? (
    <NavDrawerProvider>
      <div id="page">
        <AppHeader modul={modul} />
        <Box m={0} sx={{ display: { xs: "none", md: "block" } }}>
          <NavDrawer menu={menu || menuRoutes} />
        </Box>
        <NavDrawerContext.Consumer>
          {({ sidebarOpen }) =>
            <Main open={sidebarOpen}>
              <Toolbar variant="dense" />
              <div id="content">{props.children}</div>
            </Main>
          }
        </NavDrawerContext.Consumer>
      </div>
    </NavDrawerProvider>
  ) : (
    <Loader open={true} />
  );
};

export default AppPage;
