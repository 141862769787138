import React, { Fragment, ReactNode, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

//Material-UI Icons
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

//Custom Components
import UserContext from "@/components/UserContext/UserContext";
import HeaderButton from "@/ui/Header/HeaderButton";
import menuRoutes from "@/routes/menu";

//Types
import { UserContextType } from "@/@types/context/UserContext";

export type AppHeaderMenuButtonProps = {
  icon?: ReactNode;
  text?: string;
  startIcon?: ReactNode;
  showCloseIcon?: boolean;
};

function AppHeaderMenuButton(props: AppHeaderMenuButtonProps) {
  const userContext = useContext(UserContext) as UserContextType;
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { icon, text, startIcon, showCloseIcon } = props;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleClick = (evt: React.SyntheticEvent) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = (evt: React.SyntheticEvent, reason: string) => {
    setAnchorEl(null);
  };

  const hookAction = (action: Function) => {
    setAnchorEl(null);
    action();
  };
  const isOpen = Boolean(anchorEl);
  const menuIcon = showCloseIcon && isOpen ? <CloseIcon /> : icon ? icon : <MenuIcon />;

  return (
    <Fragment>
      <HeaderButton
        // variant="outlined"
        onClick={handleClick}
        startIcon={menuIcon ? menuIcon : null}
      >
        {text ? text : null}
      </HeaderButton>
      <Menu
        id="dialogt-toolbar-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        sx={{
          ".MuiMenu-paper": {
            mt: 1,
            padding: 2,
            backgroundColor: "palette.background",
            color: "palette.primary.main",
            width: "100%",
            maxWidth: "100%",
            left: "0px !important",
            right: "0px"
          },
          ".MuiMenu-list": {
            color: "#202020",
            fontSize: "18px",
            lineHeight: "25px",
            fontWeight: 500
          }
        }}
      >
        {menuRoutes
          .filter((x) => x.needPermission === undefined || userContext.hasAnyPermission(x.needPermission))
          .map((m, i) => {
            const action = () => {
              if (m.external) {
                window.open(m.path, "blank");
              } else {
                navigate(m.path);
              }
            };
            return (
              <MenuItem onClick={() => hookAction(action)} key={i}>
                <Typography>{t(m.ttoken)}</Typography>
              </MenuItem>
            );
          })}
      </Menu>
    </Fragment>
  );
}

export default AppHeaderMenuButton;
