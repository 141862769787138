import React, { Fragment, useContext } from "react";

//Custom components
import DialogContext from "./DialogContext";

//Types
import { DialogContextType } from "@/@types/ui/DialogContext";

const DialogContainer = () => {
  const dialogContext = useContext(DialogContext) as DialogContextType;

  const { component1, props1, component2, props2 } = dialogContext;

  return (
    <Fragment>
      {component1 ? React.createElement(component1, props1) : null}
      {component2 ? React.createElement(component2, props2) : null}
    </Fragment>
  );
};

export default DialogContainer;
