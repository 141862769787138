import { FunctionComponent, useContext, useEffect } from "react";

import { PinchRotate } from "ol/interaction";

//Custom components
import MapContext from "@/components/Map/MapContext";
import { registerOlInteraction } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";

const PinchRotateInteraction: FunctionComponent<any> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    duration: undefined,
    threshold: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, PinchRotate, props, options, events),
    []
  );

  return null;
};

export default PinchRotateInteraction;

