import { IApiResponseSuccess, LoginData } from "@/@types/lib/api/api";
import { DCRecord } from "@/@types/lib/dataController";
import {
  ActivateData,
  CheckData,
  IAuthService,
  LogoutData,
  RegisterData,
  RequestResetPasswordData,
  ResetPasswordData,
  ChangePasswordData,
  WhoAmIData
} from "@/@types/services/authService";
import api from "../lib/api";

// The authentication service is used to login and logout of the application
export const authService: IAuthService = {
  register,
  activate,
  login,
  logout,
  requestResetPassword,
  checkResetPasswordToken,
  resetPassword,
  changePassword,
  whoAmI
};

const authApiUrl = "auth/";
const appKey = process.env.REACT_APP_APPKEY;

function register(userData: DCRecord): Promise<RegisterData> {
  const apiInstance = new api();

  let bodyData = {
    ime: userData.ime,
    prezime: userData.prezime,
    email: userData.email,
    oib: userData.oib,
    telefon: userData.telefon,
    adresa: userData.adresa,
    appkey: appKey
  };

  const url = authApiUrl + "register";

  return apiInstance.Call(url, "post", bodyData);
}

function checkResetPasswordToken(token: string): Promise<CheckData> {
  const apiInstance = new api();
  const url = authApiUrl + "password-reset-check/" + token;

  return apiInstance
    .Call(url, "get")
    .then((resp) => {
      if (resp.success) {
        const data = resp.data as CheckData;
        return Promise.resolve(data);
      } else {
        return Promise.reject(resp.error);
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function activate(data: DCRecord): Promise<ActivateData> {
  const apiInstance = new api();
  const url = authApiUrl + "activate";

  return apiInstance
    .Call(url, "put", {
      email: data.email,
      activationToken: data.activation_token,
      password: data.password,
      passwordNew: data.password_new,
      appkey: appKey
    })
    .then((resp) => {
      if (resp.success) {
        const data = resp.data as LoginData;
        if (data.auth_token) {
          // store user details and basic auth credentials in local storage
          // to keep user logged in between page refreshes
          localStorage.setItem("auth_token", data.auth_token);
        }
      }
      return resp;
    });
}

function requestResetPassword(data: DCRecord): Promise<RequestResetPasswordData> {
  const apiInstance = new api();
  const url = authApiUrl + "password-reset-request";

  return apiInstance.Call(url, "post", {
    email: data.email,
    appkey: appKey
  })
  .then((resp) => {
    if (resp.success) {
      const data = resp.data as RequestResetPasswordData;
      return Promise.resolve(data);
    } else {
      return Promise.reject(resp.error);
    }
  })
  .catch((error) => {
    return Promise.reject(error);
  });
}

function resetPassword(data: DCRecord): Promise<ResetPasswordData> {
  const apiInstance = new api();
  const url = authApiUrl + "password-reset";

  return apiInstance
    .Call(url, "put", {
      // email: data.email,
      passwordreset_token: data.passwordreset_token,
      password: data.password_new,
      // appkey: appKey
    })
    .then((resp) => {
      if (resp.success) {
        const data = resp.data as ResetPasswordData;
        return Promise.resolve(data);
      } else {
        return Promise.reject(resp.error);
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function changePassword(data: DCRecord): Promise<ChangePasswordData> {
  const apiInstance = new api();
  const url = authApiUrl + "password-change";

  return apiInstance.Call(url, "put", {
    password: data.password,
    passwordNew: data.password_new
  })
  .then((resp) => {
    if (resp.success) {
      const data = resp.data as ChangePasswordData;
      return Promise.resolve(data);
    } else {
      return Promise.reject(resp.error);
    }
  })
  .catch((error) => {
    return Promise.reject(error);
  });

}

function login(username: string, password: string): Promise<LoginData> {
  const apiInstance = new api();
  const url = authApiUrl + "login";

  return apiInstance
    .Call(url, "post", {
      username: username,
      password: password
    })
    .then((resp) => {
      // console.log(resp);
      //login successful if there's a user in the response
      // console.log(resp)
      if (resp.success) {
        const data = resp.data as LoginData;
        if (data.auth_token) {
          // store user details and basic auth credentials in local storage
          // to keep user logged in between page refreshes
          localStorage.setItem("auth_token", data.auth_token);
        }
        return Promise.resolve(data);
      } else {
        return Promise.reject(resp.error);
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function logout(): Promise<LogoutData> {
  const apiInstance = new api();

  return apiInstance
    .Call("auth/logout", "post")
    .then((resp) => {
      if (resp.success) {
        const data = resp.data as LogoutData;
        localStorage.removeItem("auth_token");
        return Promise.resolve(data);
      } else {
        return Promise.reject(resp.error);
      }
    })
    .catch((err) => {
      localStorage.removeItem("auth_token");
      return err;
    });
}

function whoAmI(): Promise<WhoAmIData> {
  const apiInstance = new api();
  return apiInstance
    .Call("auth/who-am-i", "get")
    .then((resp) => {
      if (resp.success) {
        const data = resp.data as WhoAmIData;
        if (data !== undefined) {
          return Promise.resolve(data);
        } else {
          return Promise.reject({});
        }
      } else {
        return Promise.reject({});
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
