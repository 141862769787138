import * as React from "react";
import { Fragment, FunctionComponent } from "react";

const Controls: FunctionComponent<any> = (props) => {

  return (<Fragment>{props.children}</Fragment>);
};

export default Controls;

