import * as React from "react";
import { FunctionComponent } from "react";

//Types
import { IToolbarFillContent } from "@/@types/ui/Toolbar";

const ToolbarFillContent: FunctionComponent<IToolbarFillContent> = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        flexGrow: 1,
        paddingLeft: 5,
        paddingRight: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {children}
    </div>
  );
};

export default ToolbarFillContent;

