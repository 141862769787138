import React, { FunctionComponent, useState } from "react";
import DialogContext from "@/ui/DialogContext/DialogContext";

//Types
import { IDialogProvider } from "@/@types/ui/DialogContext";
import { DialogProviderState } from "@/@types/ui/DialogContext";

const DialogProvider: FunctionComponent<IDialogProvider> = (props) => {
  const [providerState, setProviderState] = useState<DialogProviderState>({
    component1: null,
    props1: {},
    component2: null,
    props2: {}
  });

  const showDialog = (component: FunctionComponent, props = {}, level = 1) => {
    if (level === 1) {
      setProviderState({
        component1: component,
        props1: props,
        component2: null,
        props2: {}
      });
    } else if (level === 2) {
      setProviderState((prevState) => {
        return {
          component1: prevState?.component1,
          props1: prevState?.props1,
          component2: component,
          props2: props
        };
      });
    }
  };

  const hideDialog = (level = 1) => {
    if (level === 1) {
      setProviderState((prevState) => {
        return {
          component1: null,
          props1: {},
          component2: null,
          props2: {}
        };
      });
    } else if (level === 2) {
      setProviderState((prevState) => {
        return {
          component1: prevState.component1,
          props1: prevState.props1,
          component2: null,
          props2: {}
        };
      });
    }
  };

  const providerValue = {
    component1: providerState?.component1,
    component2: providerState?.component2,
    props1: providerState?.props1,
    props2: providerState?.props2,
    showDialog,
    hideDialog
  };

  return <DialogContext.Provider value={providerValue}>{props.children}</DialogContext.Provider>;
};

export default DialogProvider;
